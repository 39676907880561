import React, { ReactElement } from 'react';
import dayjs from 'dayjs';
import {
    Datagrid,
    ShowButton,
    TopToolbar,
    sanitizeListRestProps,
    ListActionsProps,
    DateField,
    FilterButton,
    ReferenceField,
    TextField,
    useTranslate,
    ReferenceInput,
    AutocompleteInput,
    DateInput,
    ChipField,
    DeleteWithConfirmButton,
    useRecordContext,
    downloadCSV,
    ExportButton, useListContext, CreateButton
} from 'react-admin';
import { List } from 'react-admin';
import { InOrOutField } from './fields/InOrOutField';
import { StateField } from './fields/StateField';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { userFilter } from '../users/UserList';
import jsonExport from 'jsonexport/dist';

const exporter = async (records: any, fetchRelatedRecords: any) => {
    const users = await fetchRelatedRecords(records, 'userId', 'users')
    const sites = await fetchRelatedRecords(records, 'siteId', 'sites')

    const data = records.map((record: any) => ({
        lastname: users[record.userId].lastname,
        firstname: users[record.userId].firstname,
        date: dayjs(record.date).format('DD/MM/YYYY HH[h]mm'),
        site: sites[record.siteId].name,
    }))


    return jsonExport(data, {
        headers: ['lastname', 'firstname', 'date', 'site'],
    }, (err, csv) => {
        downloadCSV(csv, 'pointage');
    });
};


const ListActions = ({
    className,
    ...rest
}: ListActionsProps): ReactElement => {
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <FilterButton />
            <ExportButton />
            <CreateButton />
        </TopToolbar>
    );
};

const IgnoredButton = () => {
    const record = useRecordContext();
    if (record.state == "IGNORED" || record.state == "MANUAL") return null;
    return <DeleteWithConfirmButton label="Ignorer" confirmTitle="Ignorer ce pointage ?" confirmContent="" />;
};



export const ClockingList = () => {
    const t = useTranslate();
    const record = useRecordContext();

    const ClockingFilters = [
        <DateInput
            key={1}
            label={t('resources.clockings.filter.gte')}
            source="date_gte"
        />,
        <DateInput
            key={2}
            source="date_lte"
            label={t('resources.clockings.filter.lte')}
        />,
        <ReferenceInput
            key={1}
            label="resources.contracts.fields.userFullname"
            source="userId"
            reference="users"
            filterToQuery={(searchText: any) => ({ fullname: searchText })}
        >
        <AutocompleteInput optionText="fullname" source="fullname" label="resources.contracts.fields.userFullname" />
    </ReferenceInput>
    ];

    return (
        <List
            empty={false}
            actions={<ListActions />}
            filters={ClockingFilters}
            sort={{ field: 'date', order: 'DESC' }}
            filter={userFilter('userId')}
            exporter={exporter}
        >
            <Datagrid bulkActionButtons={false} sx={{ ".RaDatagrid-headerCell": {
                backgroundColor: "#6E77DA",
                fontSize: 16,
                color: "white"
            },
            "& .select-all": {
                display: "none"
              }}}>
                <ReferenceField
                    label={t('resources.users.fields.fullname')}
                    source="userId"
                    reference="users"
                    link={false}
                >
                    <TextField source="fullname" />
                </ReferenceField>
                <DateField
                    source="date"
                    options={{
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    }}
                />
                <InOrOutField />
                <StateField />
                <ReferenceField source="siteId" reference="sites" link={false}>
                    <ChipField source="name" icon={<HomeWorkIcon />} color="info" />
                </ReferenceField>
                <ReferenceField
                    label={t('pos.menu.devices')}
                    source="deviceId"
                    reference="devices"
                    link={false}
                >
                    <TextField source="name" />
                </ReferenceField>
                <ShowButton />
                <IgnoredButton />
            </Datagrid>
        </List>
    );
};
