import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    ArrayField,
    Datagrid,
    ReferenceField,
    useDataProvider,
    useTranslate,
    useRefresh,
    useNotify,
    Button, useRecordContext
} from 'react-admin';
import { useMutation } from 'react-query';
import {UserRecord} from "../../types";
import {RoleEnum} from "../../constants";
import { transitionHelperNameToColor } from '../../helpers/transition.helper';
import { Box } from '@mui/material';
import dataProvider from '../../providers/dataProvider';
import { StateField } from './fields/StateField';

const TransitionButtons = () => {
    const record = useRecordContext();
    const translate = useTranslate();

    // @ts-ignore
    return record ? (
        <span>
            {record.transitions.map(({ name } : any) => {
                return <Box component="div" sx={{ p: 1 }}><TransitionButton resource="plannings" resourceId={record.id} transitionName={name} color={transitionHelperNameToColor(name)} /></Box>
            })}
        </span>
    ) : null;
}

// @ts-ignore
const TransitionButton = ({ resource, resourceId, transitionName, color }) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const { mutate, isLoading } = useMutation(

        () => dataProvider.transition(resource, resourceId, transitionName),
        {
            onSuccess: (data) => {
                // success side effects go here
                refresh();
            },
            onError: (error) => {
                console.log(error);

                // failure side effects go here
                notify(`Comment approval error`, { type: 'error' });
            },
        }
    );
    return <Button label={`resources.${resource}.transitions.${transitionName}.label`} onClick={() => mutate()} disabled={isLoading} variant="contained" size="medium" color={color} />;
};

export const PlanningShow = () => {
    const translate = useTranslate();

    return (
    <Show title={<TextField source="name" />}>
        <SimpleShowLayout>
            <TransitionButtons/>
            <TextField source="name" />
            <StateField />
            <ArrayField source="cycles">
                <Datagrid bulkActionButtons={false}>
                    <DateField source="startingDate" />
                    <DateField source="endingDate" />
                    <ReferenceField
                        source="cycleId"
                        reference="cycles"
                        link="show"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);
};
