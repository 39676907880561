export const transitionHelperNameToColor = (transitionName: string): string => {
    const transitionsToColor : { [key: string]: string; } = {
        "to_draft": "warning",
        "awaiting": "warning",

        "to_valide" : "success",
        "reject" : "error",

        "to_validated" : "success",
        "to_rejected" : "error",
    }

    if (!transitionsToColor.hasOwnProperty(transitionName)) {
        return "error"
    }

    return transitionsToColor[transitionName]
};
