import * as React from 'react';
import {Admin, CustomRoutes, Resource, Notification} from 'react-admin';
import {Route} from 'react-router';
import {QueryClient} from 'react-query';

import {Login, Layout} from './layout';
import {lightTheme} from './layout/themes';

import absences from './app/absences';
import absenceTypes from './app/absence-types';
import users from './app/users';
import reconciliations from './app/reconciliations';
import devices from './app/devices';
import sites from './app/sites';
import categories from './app/categories';
import clockings from './app/clockings';
import contracts from './app/contracts';
import plannings from './app/plannings';
import cycles from './app/cycles';
import daysSchedules from './app/days-schedules';

import Configuration from './configuration/Configuration';
import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';
import i18nProvider from './providers/i18nProvider';
import {fetchUtils} from 'ra-core';
import SetPassword from "./set-password";
import {Toaster} from "react-hot-toast";


//export const MyNotification = () => <Notification autoHideDuration={10000} />;
export const MyNotification = () => <Notification autoHideDuration={1000}/>;


const httpClient = (url: any, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }

    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const App = () => {
    const queryClient = new QueryClient({
        // defaultOptions: {
        //     queries: {
        //         staleTime: 6000, // 1 minutes
        //     },
        // },
    });

    return (
        <>
            <Admin
                title=""
                authProvider={authProvider}
                dataProvider={dataProvider(
                    // @ts-ignore
                    process.env.REACT_APP_API_URL,
                    httpClient
                )}
                i18nProvider={i18nProvider}
                loginPage={Login}
                layout={Layout}
                disableTelemetry
                theme={lightTheme}
                queryClient={queryClient}
                notification={MyNotification}
            >
                <Resource name="clockings" {...clockings} />
                <Resource name="absences" {...absences} />
                <Resource name="absence-types" {...absenceTypes} />
                <Resource name="users" {...users} />
                <Resource name="reconciliations" {...reconciliations} />
                <Resource name="devices" {...devices} />
                <Resource name="categories" {...categories} />
                <Resource name="contracts" {...contracts} />
                <Resource name="cycles" {...cycles} />
                <Resource name="days-schedules" {...daysSchedules} />
                <Resource name="plannings" {...plannings} />
                <Resource name="sites" {...sites} />
                <Resource name="medias"/>
                <Resource name="public-holidays"/>
                <CustomRoutes>
                    <Route path="/configuration" element={<Configuration/>}/>
                    <Route path="/clocking-error" element={<Configuration/>}/>
                </CustomRoutes>
                <CustomRoutes noLayout>
                    <Route path="/set-password" element={<SetPassword/>}/>
                </CustomRoutes>
            </Admin>
            <Toaster/>
        </>
    );
};

export default App;
