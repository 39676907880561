import { Grid } from '@mui/material';
import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    useTranslate,
    DateField,
    ChipField,
} from 'react-admin';
import { InOrOutField } from './fields/InOrOutField';
import { StateField } from './fields/StateField';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

export const ClockingShow = () => {
    const t = useTranslate();
    return (
        <Show title={<TextField source="pageTitle" />}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <ReferenceField
                            label={t('resources.users.fields.fullname')}
                            source="userId"
                            reference="users"
                            link={false}
                        >
                            <TextField source="fullname" />
                        </ReferenceField>
                        <DateField
                            source="date"
                            options={{
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            }}
                        />
                        <InOrOutField />
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                    <StateField />
                    <ReferenceField source="siteId" reference="sites" link={false}>
                        <ChipField source="name" icon={<HomeWorkIcon />} color="info" />
                    </ReferenceField>
                    <ReferenceField
                        label={t('resources.devices.fields.name')}
                        source="deviceId"
                        reference="devices"
                        link="show"
                    >
                        <ChipField source="name" />
                    </ReferenceField>
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    );
};
