import React, { ReactElement } from 'react';
import {
    Datagrid,
    TextField,
    List,
    ChipField,
    ReferenceField,
    DateField,
    ShowButton, EditButton,
} from 'react-admin';
import WorkIcon from '@mui/icons-material/Work';
import {RoleField} from "../users/fields/RoleField";


export const ReconciliationList = () => {
    return (
        <List
            empty={false}
            filter={{state: 'TODO'}}
        >
            <Datagrid bulkActionButtons={false} sx={{ ".RaDatagrid-headerCell": {
                backgroundColor: "#6E77DA",
                fontSize: 16,
                color: "white"
            }}}>
                <ReferenceField
                    label="resources.users.fields.fullname"
                    source="userId"
                    reference="users"
                    link={false}
                >
                    <TextField source="fullname" />
                </ReferenceField>
                <DateField
                    source="referenceDate"
                    options={{
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    }}
                />
                <ReferenceField
                    source="categoryId"
                    reference="categories"
                    link="show"
                    label="resources.category.name"
                    sortable={false}
                >
                    <ChipField source="name" icon={<WorkIcon />} color="info" />
                </ReferenceField>
                <RoleField />
                <EditButton />
            </Datagrid>
        </List>
    );
};
