import { useTranslate } from 'react-admin';
import React from 'react';

const EmptyResult = () => {
    const translate = useTranslate();

    return <span>{translate(`common.emptyResult`)}</span>;
};

export default EmptyResult;
