import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
    TextInput,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextField,
    useTranslate,
} from 'react-admin';
import SectionTitle from '../../components/SectionTitle';
import { Box } from '@mui/material';
import { ReplaceEmptyStringsByNullInObjectRecursively } from '../../utils/form';

export const SiteCreate = () => {
    return (
        <Create title={<TextField source="name" />} transform={siteTransform} redirect="show">
            <SiteForm />
        </Create>
    );
};

export const siteTransform = (data: any) => ({
    ...ReplaceEmptyStringsByNullInObjectRecursively(data),
});

export const siteValidationSchema = yup
    .object()
    .shape({
        name: yup.string().required(),
        categoryId: yup.string().required(),
    })
    .required();

export const SiteForm = () => {
    const translate = useTranslate();

    return (
        <SimpleForm
            resolver={yupResolver(siteValidationSchema)}
            sx={{ maxWidth: 500 }}
        >
            <TextInput source="name" fullWidth isRequired />
            <ReferenceInput source="categoryId" reference="categories">
                <SelectInput
                    optionText="name"
                    isRequired
                    label="resources.sites.fields.category"
                />
            </ReferenceInput>

            <SectionTitle label="resources.sites.fieldGroups.address" />
            <Box display={{ xs: 'block', sm: 'flex'}} sx={{mb:2, mt:2}}>
                
                <TextInput
                    source="location.addressLine1"
                    label="resources.sites.fields.location.addressLine1"
                    multiline
                    fullWidth
                    isRequired
                    helperText={false}
                />
                <TextInput
                    source="location.addressLine2"
                    label="resources.sites.fields.location.addressLine2"
                    multiline
                    helperText={false}
                />
            </Box>
            <Box display={{ xs: 'block', sm: 'flex' }}>
                    <TextInput
                        source="location.city"
                        label="resources.sites.fields.location.city"
                        isRequired
                        helperText={false}
                    />
                    <TextInput
                        source="location.postalCode"
                        label="resources.sites.fields.location.postalCode"
                        isRequired
                        helperText={false}
                    />
            </Box>
            <Box display={{ xs: 'block', sm: 'flex' }}>
                    <SelectInput
                        isRequired
                        defaultValue="FR"
                        source="location.countryCode"
                        label="resources.sites.fields.location.countryCode"
                        choices={[
                            {
                                id: 'FR',
                                name: translate('common.countries.FR'),
                            },
                        ]}
                    />
            </Box>
        </SimpleForm>
    );
};
