import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    TextField,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    BooleanInput,
    FormDataConsumer,
    TimeInput,
    NumberInput,
} from 'react-admin';
import { ReplaceEmptyStringsByNullInObjectRecursively } from '../../utils/form';
import { Box } from '@mui/material';
import InputGroupTitle from '../../components/InputGroupTitle';

export const AbsenceCreate = () => {
    return (
        <Create
            title={<TextField source="name" />}
            transform={ReplaceEmptyStringsByNullInObjectRecursively}
            redirect="list"
        >
            <AbsenceTypeForm />
        </Create>
    );
};


export const AbsenceTypeForm = () => {

    return (
        <SimpleForm
            sx={{ maxWidth: 500 }}
        >
            <ReferenceInput source="userId" reference="users">
                <AutocompleteInput
                    label="resources.absences.form.userFullname"
                    optionText="fullname"
                    isRequired
                    fullWidth
                />
            </ReferenceInput>

            <Box display={{xs:'block', sm:'flex', width:'100%'}}>
                <ReferenceInput source="absenceTypeId" reference="absence-types">
                    <SelectInput
                        label="resources.absences.form.absenceType"
                        optionText="name"
                        isRequired
                        sx={{width:'250px'}}
                    />
                </ReferenceInput>

                <NumberInput source="amount" label="resources.absences.form.amount" defaultValue="0.5" min="0.5" step="0.5" sx={{width:'80px', mt:1, ml:1, mr:0.5}} isRequired />
                <FormDataConsumer>
                    {({ formData }) =>
                        (formData.absenceType === "PAID_LEAVE" || formData.absenceType === "GRANTED_LEAVE" || formData.absenceType === "SICK_CHILD_DAY" || formData.absenceType === "CET") && (
                            <>
                                <span>(en jour)</span>
                            </>
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData }) =>
                        (formData.absenceType === "EXCEPTIONAL_HOUR" || formData.absenceType === "BALANCE") && (
                            <>
                                <span>(en heure)</span>
                            </>
                        )
                    }
                </FormDataConsumer>
            </Box>

            <Box display={{xs:'block', sm:'flex', width:'100%'}}>
                <DateInput
                    source="startingDate"
                    label="resources.absences.form.startingDate"
                    isRequired
                />
                <DateInput
                    source="endingDate"
                    label="resources.absences.form.endingDate"
                    isRequired
                    sx={{ml:1}}
                />
            </Box>

            <BooleanInput
                source="allDay"
                label="resources.absences.form.allDay"
                defaultValue={false}
            />
            <FormDataConsumer>
                {({ formData }) =>
                    formData.allDay === false && (
                        <>
                            <InputGroupTitle CustomLabel="resources.absences.form.timeTitle" />
                            <Box display={{xs:'block', sm:'flex', width:'100%'}}>
                                <TextInput label="resources.absences.form.startingTime" source="startingTime" sx={{width:100}} defaultValue='00:00' />
                                <TextInput label="resources.absences.form.endingTime" source="endingTime" sx={{width:100, ml:1}} defaultValue='00:00' />
                            </Box>
                        </>
                    )
                }
            </FormDataConsumer>

            <TextInput source="comment" label="resources.absences.form.comment" fullWidth />
        </SimpleForm>
    );
};
