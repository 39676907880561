import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  SimpleForm,
  FormDataConsumer,
  SelectInput,
  TextField,
  Edit,
  SimpleFormIterator,
  TimeInput,
  ArrayInput,
  NumberInput,
  useTranslate,
  useGetList,
  Loading,
  Datagrid,
  ReferenceField,
  ChipField,
  DateField,
  ArrayField,
  Toolbar,
  SaveButton,
  useRecordContext,
  SimpleShowLayout,
} from "react-admin";
import { ReplaceEmptyStringsByNullInObjectRecursively } from "../../utils/form";
import { InOrOutField } from "../clockings/fields/InOrOutField";
import { StateField } from "../clockings/fields/StateField";
import { Grid, List, Typography } from "@mui/material";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ArrayTitle from "../../components/ArrayTitle";
import { Box } from "@mui/system";
import { UserRecord } from "../../types";

const UserEditToolbar = () => (
  <Toolbar>
      <SaveButton />
  </Toolbar>
);

export const Aside = () => {
  const t = useTranslate();

  const {
    data: AbsenceTypeArray,
    total,
    isLoading,
    error,
  } = useGetList("absence-types", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "name", order: "ASC" },
  });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  // @ts-ignore
  // @ts-ignore
return (

  // Formulaire
  <Box sx={{ width: '300px', margin: '1em' }}>
     
    <SimpleForm
      resolver={yupResolver(reconliationValidationSchema)}
      toolbar={<UserEditToolbar />}
    >

      <ArrayTitle CustomLabel="resources.reconciliations.options" />
      <ArrayInput label="Pointages" source="manualClockings">
        <SimpleFormIterator>
          <TimeInput source="time" label="Heure" />
          <SelectInput
            source="inOrOut"
            label="resources.clockings.inOrOut.label"
            choices={[
              { id: "in", name: "resources.clockings.inOrOut.in" },
              { id: "out", name: "resources.clockings.inOrOut.out" },
            ]}
          />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput label="Actions" source="manualClockings">
        <SimpleFormIterator>
          <SelectInput source="resource" choices={AbsenceTypeArray} />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              AbsenceTypeArray &&
              AbsenceTypeArray.find((absenceType) => absenceType.reasons)
                .reasons && (
                <SelectInput
                  source="reasonId"
                  label="resources.absence-types.reasons.label"
                  choices={
                    AbsenceTypeArray.find((absenceType) => absenceType.reasons)
                      .reasons
                  }
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <SelectInput
            source="plusMinus"
            label="resources.reconciliations.plusMinus.label"
            choices={[
              { id: "plus", name: "resources.reconciliations.plusMinus.plus" },
              {
                id: "minus",
                name: "resources.reconciliations.plusMinus.minus",
              },
            ]}
          />
          <NumberInput source="amount" label="Valeur" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Box>);
};

export const ReconciliationEdit = () => {
  return (
    <Edit
      title={
        <ReferenceField
            source="userId"
            reference="users"
            link={false}
        >
          <TextField source="fullname" />
        </ReferenceField>
      }
      transform={ReplaceEmptyStringsByNullInObjectRecursively}
      aside={<Aside />}
    >
      <ReconciliationInfos />
    </Edit>
  );
};

export const ClockingsArrayTitle = ({ dateLabel }: { dateLabel: any }) => {
  const record = useRecordContext<UserRecord>();
  return (
    <Typography variant="h6" gutterBottom>
      <span>Pointages du </span>
      <DateField
        source={dateLabel}
        options={{
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }}
        sx={{fontSize:'18px', fontWeight:'bold'}}
      />
    </Typography>
  );
};

export const AgentInfosTitle = ({ CustomLabel }: { CustomLabel: any }) => {
  const record = useRecordContext<UserRecord>();
  return (
    <Typography variant="h6" gutterBottom>
      <span>Informations de  </span>
        <ReferenceField
          source={CustomLabel}
          reference="users"
          link={false}
        >
          <TextField source="fullname" sx={{fontSize:'18px', fontWeight:'bold'}} />
        </ReferenceField>
    </Typography>
  );
};

export const reconliationValidationSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    siteId: yup.string().required(),
  })
  .required();


export const ReconciliationInfos = () => {
  
  return (
      <List sx={{padding:'10px'}}>

        {/* Infos client/contrat */}
        <AgentInfosTitle CustomLabel="userId" />
        <Grid container spacing={2} textAlign="center" sx={{mb:3}}>
          <Grid item xs={2} >
            <SimpleShowLayout sx={{alignItems:"bottom"}}>
              <TextField source="ca" label="resources.absence-types.resource.PAID_LEAVE" />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={3}>
            <SimpleShowLayout>
              <TextField source="exceptionnalHours" label="resources.absence-types.resource.EXCEPTIONAL_HOUR" />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={2}>
            <SimpleShowLayout>
              <TextField source="balance" label="resources.absence-types.resource.BALANCE" />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={3}>
            <SimpleShowLayout>
              <TextField source="sickChildDay" label="resources.absence-types.resource.SICK_CHILD_DAY" />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={1}>
            <SimpleShowLayout>
              <TextField source="cet" label="resources.absence-types.resource.CET" />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={1}>
            <SimpleShowLayout>
              <TextField source="jhp" label="resources.absence-types.resource.JHP" />
            </SimpleShowLayout>
          </Grid>
        </Grid>  

        {/* Liste des Clockings */}
        <ClockingsArrayTitle dateLabel="date" />
        <ArrayField source="clockings">
          <Datagrid bulkActionButtons={false} sx={{
              backgroundColor: "Lavender",
              "& .RaDatagrid-headerCell": {
                  backgroundColor: "slateblue",
              },
              marginBottom:'20px'
          }}>
            <DateField
              source="date"
              options={{
                  weekday: "short",
                  hour: 'numeric',
                  minute: 'numeric'
              }}
            />
            <InOrOutField />
            <StateField />
            <ReferenceField source="siteId" reference="sites" link={false}>
                <ChipField source="name" icon={<HomeWorkIcon />} color="info" />
            </ReferenceField>
            <ReferenceField
                label="pos.menu.devices"
                source="deviceId"
                reference="devices"
                link={false}
            >
              <TextField source="name" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>

        {/* Liste des plannings Event */}
        <ArrayTitle CustomLabel="resources.reconciliations.planningEvent" />
        <ArrayField source="planningEvents">
          <Datagrid bulkActionButtons={false} sx={{
              backgroundColor: "Lavender",
              "& .RaDatagrid-headerCell": {
                  backgroundColor: "slateblue",
              }
          }}>
              <TextField source="title" label="resources.days-schedules.fields.periods.name" />
              <TextField source="startingTitle" label="resources.days-schedules.fields.periods.startingName" />
              <DateField showTime={true} showDate={false} options={{ hour:'numeric', minute:'numeric' }} source="startingDatetime" label="resources.days-schedules.fields.periods.startingTime" />
              <TextField source="endingTitle" label="resources.days-schedules.fields.periods.endingName" />
              <DateField showTime={true} showDate={false} options={{ hour:'numeric', minute:'numeric' }} source="endingDatetime" label="resources.days-schedules.fields.periods.endingTime" />
          </Datagrid>
        </ArrayField>
      </List>
  );
};
