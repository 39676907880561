function isObjectEmpty(obj: object) {
    if (Object.keys(obj).length === 0) return true;
    return false;
}

export function ReplaceEmptyStringsByNullInObjectRecursively(obj: any) {
    let key;

    for (key in obj) {
        console.log(key, obj[key]);

        if (obj[key] === null) {
            console.log('NULL');
            continue;
        }

        if (typeof obj[key] === 'string') {
            console.log('STRING');
            if (obj[key] == '') {
                obj[key] = null;
            }
        } else if (Array.isArray(obj[key])) {
            console.log('ARRAY');
            if (obj[key].length === 0) {
                obj[key] = [];
            } else {
                ReplaceEmptyStringsByNullInObjectRecursively(obj[key]);
            }
        } else if (typeof obj[key] === 'object') {
            // console.log('OBJECT');
            if (isObjectEmpty(obj[key])) {
                // obj[key] = null;
            } else {
                ReplaceEmptyStringsByNullInObjectRecursively(obj[key]);
            }
        }
    }

    console.log(obj);

    return obj;
}
