import React from 'react';
import { Edit, TextField } from 'react-admin';
import { DeviceForm } from './DeviceCreate';
import { ReplaceEmptyStringsByNullInObjectRecursively } from '../../utils/form';

export const DeviceEdit = () => {
    return (
        <Edit
            title={<TextField source="name" />}
            transform={ReplaceEmptyStringsByNullInObjectRecursively}
            redirect="show"
        >
            <DeviceForm />
        </Edit>
    );
};
