import React, { ReactElement } from 'react';
import {
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    TopToolbar,
    CreateButton,
    SelectInput,
    sanitizeListRestProps,
    FilterButton,
    ListActionsProps,
    List,
    ChipField,
    ReferenceField, useGetIdentity, usePermissions, AutocompleteInput, ReferenceInput,
} from 'react-admin';
import { RoleField } from './fields/RoleField';
import WorkIcon from '@mui/icons-material/Work';
import {RoleEnum} from "../../constants";

const userFilters = [
    <SelectInput
        key={1}
        source="roles"
        label="resources.users.fields.role.label"
        choices={[
            { id: 'SUPER_ADMIN', name: 'DGS' },
            { id: 'ADMIN', name: 'Administrateur' },
            { id: 'ABSENCES_MANAGER', name: 'Gestionnaire absences' },
            { id: 'CATEGORY_MANAGER', name: 'Chef de service' },
            { id: 'AGENT', name: 'Agent' }
        ]}
    />,
    <ReferenceInput source="id" reference="users" label="resources.contracts.fields.userFullname">
        <AutocompleteInput
            label="resources.contracts.fields.userFullname"
            optionText="fullname"
        />
    </ReferenceInput>
];

const ListActions = ({
    className,
    ...rest
}: ListActionsProps): ReactElement => {
    const { isLoading, permissions } = usePermissions();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <FilterButton />

            {permissions == RoleEnum.SUPER_ADMIN && 
                <CreateButton />
            }
            
        </TopToolbar>
    );
};

export const userFilter = (customTargetId:string) => {
    const { data: userIdentity, isLoading: identityLoading } = useGetIdentity();
    const { isLoading, permissions } = usePermissions();

    switch (permissions) {
        case RoleEnum.SUPER_ADMIN:
            return {}
        case RoleEnum.CATEGORY_MANAGER:
            return { categoryId: userIdentity?.managedCategoryId }
        case RoleEnum.AGENT:
            return { [customTargetId]: userIdentity?.id }
    }
}

export const UserList = () => {

    const { isLoading, permissions } = usePermissions();

    return (
        <List
            empty={false}
            actions={<ListActions />}
            sort={{ field: 'lastname', order: 'ASC' }}
            filter={userFilter('id')}
            filters={userFilters}
        >
            <Datagrid bulkActionButtons={false} sx={{ ".RaDatagrid-headerCell": {
                backgroundColor: "#6E77DA",
                fontSize: 16,
                color: "white"
            },
            "& .select-all": {
                display: "none"
              }}}>
                <TextField source="fullname" />
                <ReferenceField
                    source="categoryId"
                    reference="categories"
                    link="show"
                >
                    <ChipField source="name" icon={<WorkIcon />} color="info" />
                </ReferenceField>
                <RoleField />

                {permissions == RoleEnum.SUPER_ADMIN && 
                    <EditButton />
                }
                
                <ShowButton />
            </Datagrid>
        </List>
    );
};
