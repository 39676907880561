import {
    FieldProps,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { UserRecord } from '../../types';
import React from 'react';

export const PlusMinusField = (props: FieldProps<UserRecord>) => {
    const record = useRecordContext<UserRecord>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate(`resources.reconciliations.plusMinus.${record.plusMinus}`)}
        </span>
    ) : null;
};

PlusMinusField.defaultProps = { label: 'resources.reconciliations.plusMinus.label' };
