import HowToRegIcon from '@mui/icons-material/HowToReg';

import { ClockingList } from './ClockingList';
import { ClockingShow } from './ClockingShow';
import {ClockingCreate} from "./ClockingCreate";

export default {
    list: ClockingList,
    show: ClockingShow,
    create: ClockingCreate,
    icon: HowToRegIcon,
};
