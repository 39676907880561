import React, { ReactElement } from 'react';
import {
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    TopToolbar,
    CreateButton,
    sanitizeListRestProps,
    ListActionsProps,
    DateField,
    ReferenceField,
    useTranslate,
    ReferenceInput,
    AutocompleteInput,
    FilterButton,
    BooleanField,
    List,
    FileField
} from 'react-admin';
import { userFilter } from '../users/UserList';

const ContractFilters = [
    <ReferenceInput
        key={1}
        label="resources.contracts.fields.userFullname"
        source="userId"
        reference="users"
        filterToQuery={(searchText: any) => ({ fullname: searchText })}
    >
        <AutocompleteInput optionText="fullname" source="fullname" label="resources.contracts.fields.userFullname" />
    </ReferenceInput>
];

const ListActions = ({
    className,
    ...rest
}: ListActionsProps): ReactElement => {
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <FilterButton />
            <CreateButton />
        </TopToolbar>
    );
};

export const ContractList = () => {
    const t = useTranslate();
    return (
        <List
            actions={<ListActions />}
            filters={ContractFilters}
            sort={{ field: 'user.lastname', order: 'ASC' }}
            filter={userFilter('userId')}
        >
            <Datagrid bulkActionButtons={false} sx={{ ".RaDatagrid-headerCell": {
                backgroundColor: "#6E77DA",
                fontSize: 16,
                color: "white"
            },
            "& .select-all": {
                display: "none"
              }}}>
                <ReferenceField
                    label={t('resources.users.fields.fullname')}
                    source="userId"
                    sortBy="user.lastname"
                    reference="users"
                    link={false}
                >
                    <TextField source="fullname" />
                </ReferenceField>
                <DateField
                    source="startingDate"
                    options={{
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    }}
                />
                <DateField
                    source="endingDate"
                    options={{
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    }}
                /> 
                <ReferenceField
                    label={t('resources.plannings.fields.planning')}
                    source="mediaId"
                    reference="medias"
                    link={false}
                    sortable={false}
                >
                    <FileField
                        source="url"
                        target="_blank"
                        title="planning.pdf"
                    />
                </ReferenceField>
                <BooleanField source="isPermanent" />
                <BooleanField source="isActive" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    );
};
