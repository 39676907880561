import NoteIcon from '@mui/icons-material/Note';

import { ContractList } from './ContractList';
import { ContractShow } from './ContractShow';
import { ContractEdit } from './ContractEdit';
import { ContractCreate } from './ContractCreate';

export default {
    list: ContractList,
    edit: ContractEdit,
    show: ContractShow,
    create: ContractCreate,
    icon: NoteIcon,
};
