import {
    FieldProps,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { UserRecord } from '../../../types';
import React from 'react';
import { Typography } from '@mui/material';


export const StateField = (props: FieldProps<UserRecord>) => {
    const record = useRecordContext<UserRecord>();
    const translate = useTranslate();

    const statusColors : any = {
        ERROR: 'red',
        AWAITING_CHECK: 'orange',
        CHECKED: 'green',
        UNDEFINED: 'red',
        RESOLVED: 'green',
        MANUAL: '#0495FB'
    }

    return record ? (
        <Typography style={{ fontWeight: 600 }} sx={{ color: statusColors[record.state] ?? "666666" }} >
        
            {translate(`resources.clockings.state.${record.state}`)}
        </Typography>
    ) : null;
};

StateField.defaultProps = { label: 'resources.clockings.state.label' };
