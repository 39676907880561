import {
    FieldProps,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { UserRecord } from '../../../types';
import React from 'react';

export const StateField = (props: FieldProps<UserRecord>) => {
    const record = useRecordContext<UserRecord>();
    const translate = useTranslate();

    return record ? (
        <span>
            <span>
                {translate(`resources.plannings.fields.state.${record.state}`)}
            </span>
        </span>
    ) : null;
};

StateField.defaultProps = { label: 'resources.plannings.fields.state.label' };
