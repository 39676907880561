import React from 'react';
import { Edit, TextField } from 'react-admin';
import { UserForm, userTransform } from './UserCreate';

export const UserEdit = () => {
    return (
        <Edit
            redirect="list"
            title={<TextField source="fullname" />}
            transform={userTransform}
        >
            <UserForm />
        </Edit>
    );
};
