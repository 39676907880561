import RuleIcon from '@mui/icons-material/Rule';
import { AbsenceCreate } from './AbsenceCreate';
import { AbsenceList } from './AbsenceList';
import { AbsenceShow } from './AbsenceShow';
import { AbsenceEdit } from './AbsenceEdit';

export default {
    create: AbsenceCreate,
    edit: AbsenceEdit,
    list: AbsenceList,
    show: AbsenceShow,
    icon: RuleIcon,
};
