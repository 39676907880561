import { Box, Grid } from '@mui/material';
import React from 'react';
import { Show, TextField, SimpleShowLayout, ReferenceField, DateField, BooleanField, useRecordContext, useDataProvider, useRefresh, useNotify, Button, usePermissions} from 'react-admin';
import { useMutation } from 'react-query';
import { transitionHelperNameToColor } from '../../helpers/transition.helper';
import {RoleEnum} from "../../constants";

const TransitionButtons = () => {
    const record = useRecordContext();
    const { isLoading, permissions } = usePermissions();

    if (permissions != "SUPER_ADMIN") return null;

    // @ts-ignore
    return record ? (
        <Grid container spacing={2}>
            {record.transitions && record.transitions.map(({ name } : any) => {
                return (
                    <Grid item xs={6}>
                        <Box component="div" sx={{ p: 1 }}><TransitionButton resource="absences" resourceId={record.id} transitionName={name} color={transitionHelperNameToColor(name)} /></Box>
                    </Grid>
                )
            })}
        </Grid>
    ) : null;
}

// @ts-ignore
const TransitionButton = ({ resource, resourceId, transitionName, color }) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const { mutate, isLoading } = useMutation(

        () => dataProvider.transition(resource, resourceId, transitionName),
        {
            onSuccess: (data) => {
                // success side effects go here
                refresh();
            },
            onError: (error) => {
                console.log(error);

                // failure side effects go here
                notify(`Comment approval error`, { type: 'error' });
            },
        }
    );
    return <Button label={`resources.${resource}.transitions.${transitionName}.label`} onClick={() => mutate()} disabled={isLoading} variant="contained" size="medium" color={color} />;
};

const ShowTime = () => {
    const record = useRecordContext();
    if (record.allDay) return null;
    return (
        <>
       De <TextField source="startingTime" /> à <TextField source="endingTime" />
       </>
    )
};

export const AbsenceShow = () => {

    return (
        <Show title={<TextField source="name" />}>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <TransitionButtons />
                        <ReferenceField source="userId" reference="users" label="resources.contracts.fields.userFullname">
                            <TextField source="fullname" />
                        </ReferenceField>
                    </SimpleShowLayout>
                    <SimpleShowLayout>
                        <ReferenceField source="absenceTypeId" reference="absence-types" label="resources.absences.form.absenceType">
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="amountText" label="resources.absences.form.amount" sx={{width:'80px', mt:1, ml:1, mr:0.5}} />
                    </SimpleShowLayout>
                </Grid>

                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <DateField source="startingDate" label="resources.absences.form.startingDate" />
                        <DateField source="endingDate" label="resources.absences.form.endingDate" />
                        <BooleanField source="allDay" label="resources.absences.form.allDay" />
                        <ShowTime />
                    </SimpleShowLayout>
                </Grid>
            </Grid>
            
            <SimpleShowLayout>
                <TextField source="comment" label="resources.absences.form.comment" fullWidth />
            </SimpleShowLayout>
        </Show>
    );
};
