import React from 'react';
import { NumberFieldProps, useRecordContext, useTranslate } from 'react-admin';

export const DayStringField = ({ source }: NumberFieldProps) => {
    const record = useRecordContext();
    const translate = useTranslate();

    if (!record || !source) {
        return null;
    }

    let dayNumber;
    const sourceInt = record[source];

    if (sourceInt === 0 || sourceInt % 7 === 0) dayNumber = 0;
    if (sourceInt === 1 || sourceInt % 7 === 1) dayNumber = 1;
    if (sourceInt === 2 || sourceInt % 7 === 2) dayNumber = 2;
    if (sourceInt === 3 || sourceInt % 7 === 3) dayNumber = 3;
    if (sourceInt === 4 || sourceInt % 7 === 4) dayNumber = 4;
    if (sourceInt === 5 || sourceInt % 7 === 5) dayNumber = 5;
    if (sourceInt === 6 || sourceInt % 7 === 6) dayNumber = 6;

    return <span>{translate(`common.weekDays.${dayNumber}`)}</span>;
};
