import React, { ReactElement } from 'react';
import {
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    TopToolbar,
    CreateButton,
    sanitizeListRestProps,
    ListActionsProps,
    List,
    ReferenceField,
    useTranslate,
    DateField,
    useRecordContext,
    useListContext,
} from 'react-admin';
import { StateField } from './field/StateField';

const ListActions = ({
    className,
    ...rest
}: ListActionsProps): ReactElement => {
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <CreateButton />
        </TopToolbar>
    );
};


const IsEditable = () => {
    const record = useRecordContext();
    if (record.state != "AWAITING_VALIDATION") return null;

    return (
        <>
            <EditButton />
        </>
    );
}

export const AbsenceList = () => {
    const translate = useTranslate();
    const { data, isLoading } = useListContext();
    if (isLoading) return null;

    return (
        <List empty={false} actions={<ListActions />}>
            <Datagrid bulkActionButtons={false} sx={{ ".RaDatagrid-headerCell": {
                backgroundColor: "#6E77DA",
                fontSize: 16,
                color: "white"
            },
            "& .select-all": {
                display: "none"
              }}}>
                <ReferenceField
                    label={translate('resources.users.fields.fullname')}
                    source="userId"
                    reference="users"
                    link={false}
                >
                    <TextField source="fullname" />
                </ReferenceField>
                <ReferenceField
                    label="resources.absences.form.absenceType"
                    source="absenceTypeId"
                    reference="absence-types"
                    link={false}
                >
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="amount" label="resources.absences.form.amount" />
                <DateField label="resources.absences.form.startingDate" source="startingDate" />
                <DateField label="resources.absences.form.endingDate" source="endingDate" />
                <StateField />
                <IsEditable />
                <ShowButton />
            </Datagrid>
        </List>
    );
};
