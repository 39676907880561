import React, { ChangeEvent } from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    required,
    minLength,
    SimpleFormIterator,
    SelectInput,
    SelectInputProps,
    ArrayInput,
    ReferenceInput,
    FormDataConsumer,
} from 'react-admin';
import { Number0ToDayString } from '../../helpers/day.helper';
import { ReplaceEmptyStringsByNullInObjectRecursively } from '../../utils/form';

const validateName = [required(), minLength(2)];

interface ColorOption {
    name: string;
    value: string;
}
const ColorSelect: React.FC<SelectInputProps> = (props) => {
    
    const colorOptions = [
        { name: 'Bleu 1', value: '#0000FF' },
        { name: 'Bleu 2', value: '#3366FF' },
        { name: 'Bleu 3', value: '#6699FF' },
        { name: 'Bleu 4', value: '#99CCFF' },
        { name: 'Bleu 5', value: '#CCFFFF' },
        { name: 'Orange 1', value: '#FF6600' },
        { name: 'Orange 2', value: '#FF9900' },
        { name: 'Orange 3', value: '#FFCC00' },
        { name: 'Orange 4', value: '#FFCC33' },
        { name: 'Orange 5', value: '#FFCC66' },
        { name: 'Vert 1', value: '#00FF00' },
        { name: 'Vert 2', value: '#33FF33' },
        { name: 'Vert 3', value: '#66FF66' },
        { name: 'Vert 4', value: '#99FF99' },
        { name: 'Vert 5', value: '#CCFFCC' },
        { name: 'Marron 1', value: '#D7B899' },
        { name: 'Marron 2', value: '#C6A680' },
        { name: 'Marron 3', value: '#B5936A' },
        { name: 'Marron 4', value: '#A67F54' },
        { name: 'Marron 5', value: '#976D3F' }
    ];

    const getOptionText = (option: ColorOption) => (
        <div>
        <div
            style={{
                backgroundColor: option.value,
                width: '30px',
                height: '10px',
                display: 'inline-block',
                marginLeft: '5px',
                marginRight: '10px',
            }}
        />
        {option.name}
        </div>
    );

    return (
        <SelectInput
            label="Couleur"
            source="color"
            choices={colorOptions}
            optionText={getOptionText}
            optionValue="value"
            isRequired
            {...props}
        />
    );
};
export default ColorSelect;
    
    
export const CycleCreate = () => {
    return (
        <Create transform={ReplaceEmptyStringsByNullInObjectRecursively} redirect="list">
            <SimpleForm>
                <TextInput source="name" validate={validateName} />
                <ReferenceInput source="categoryId" reference="categories">
                    <SelectInput optionText="name" isRequired />
                </ReferenceInput>
                <ColorSelect/>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.categoryId && (
                            <ArrayInput
                                label={false}
                                source="daySchedules"
                                {...rest}
                            >
                                <SimpleFormIterator
                                    disableReordering
                                    getItemLabel={(index: number) =>
                                        `Jour ${
                                            index + 1
                                        }: ${Number0ToDayString(index + 1)}`
                                    }
                                >
                                    <ReferenceInput
                                        source="id"
                                        reference="days-schedules"
                                        filter={{
                                            categoryId: formData.categoryId,
                                        }}
                                    >
                                        <SelectInput
                                            label="resources.cycles.fields.daySchedule"
                                            optionText="name"
                                            emptyText={'Journée de repos'}
                                        />
                                    </ReferenceInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        )
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};
