import React, { FC, ReactElement } from 'react';
import {
    Datagrid,
    ShowButton,
    TopToolbar,
    CreateButton,
    sanitizeListRestProps,
    ListActionsProps,
    TextField,
    List,
    EditButton,
    ChipField,
    ReferenceField,
    FileField, useTranslate,
} from 'react-admin';
import { StateField } from './fields/StateField';
import WorkIcon from '@mui/icons-material/Work';

const ListActions = ({
    className,
    ...rest
}: ListActionsProps): ReactElement => {
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <CreateButton />
        </TopToolbar>
    );
};


export const PlanningList = () => {
    const t = useTranslate();
    return (
        <List
            actions={<ListActions />}
            empty={false}
            sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid sx={{ ".RaDatagrid-headerCell": {
                backgroundColor: "#6E77DA",
                fontSize: 16,
                color: "white"
            },
            "& .select-all": {
                display: "none"
              }}}>
                <TextField source="name" />
                <ReferenceField
                    source="categoryId"
                    reference="categories"
                    link="show"
                    label="resources.plannings.fields.categoryId"
                >
                    <ChipField source="name" icon={<WorkIcon />} color="info" />
                </ReferenceField>
                <StateField />
                <ReferenceField
                    label={t('resources.plannings.fields.media')}
                    source="mediaId"
                    reference="medias"
                    link={false}
                    sortable={false}
                >
                    <FileField
                        source="url"
                        target="_blank"
                        title="planning.pdf"
                    />
                </ReferenceField>
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    );
};
