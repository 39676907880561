import React from 'react';
import { TextField, useTranslate, Datagrid, List, ChipField } from 'react-admin';
import WorkIcon from '@mui/icons-material/Work';


export const CategoryList = () => {
    const translate = useTranslate();
    return (
        <List
            title={translate('pos.menu.categories')}
            empty={false}
            pagination={false}
            sort={{ field: 'name', order: 'ASC' }}
        >
            <Datagrid bulkActionButtons={false} sx={{ ".RaDatagrid-headerCell": {
                backgroundColor: "#6E77DA",
                fontSize: 16,
                color: "white"
            }}}>
                <TextField source="name" sortable={false} label="resources.category.name" />
                <ChipField source="name" icon={<WorkIcon />} color="info" label="resources.category.iconName" sortable={false} />
            </Datagrid>
        </List>
    );
};
