import React from 'react';
import {
    Show,
    TabbedShowLayout,
    TextField,
    Tab,
    ReferenceManyField,
    Datagrid,
    DateField,
    BooleanField,
    useTranslate,
    WrapperField,
    SimpleShowLayout,
    ReferenceField,
    ChipField,
    ShowButton,
    ArrayField,
    FileField,
    NumberField, useRedirect, useGetRecordId
} from 'react-admin';
import EmptyResult from '../../components/EmptyResult';
import WorkIcon from '@mui/icons-material/Work';
import { Grid } from '@mui/material';
import { RoleField } from './fields/RoleField';
import { InOrOutField } from '../clockings/fields/InOrOutField';
import { StateField } from '../clockings/fields/StateField';
import ArrayTitle from '../../components/ArrayTitle';
import { PlusMinusField } from '../../components/PlusMinus/InOrOutField';
import Button from "@mui/material/Button";


export const UserShow = () => {
    const translate = useTranslate();
    const redirect = useRedirect()
    const userId = useGetRecordId();

    return (
        <Show title={<TextField source="fullname" />}>
            <TabbedShowLayout>

                {/** AGENT **/}
                <Tab
                    label={translate(`resources.users.name`, {
                        smart_count: 1,
                    })}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <SimpleShowLayout>
                                <BooleanField source="isActive" />
                                <ReferenceField source="categoryId" reference="categories" link="show">
                                    <ChipField source="name" icon={<WorkIcon />} color="info" />
                                </ReferenceField>
                                <WrapperField label={translate(`resources.contracts.fields.userFullname`, {
                                    smart_count: 2,
                                })}>
                                    <TextField source="gender" sx={{mr:1}}/>
                                    <TextField source="fullname" />
                                </WrapperField>
                                <WrapperField label={translate(`resources.users.fieldGroups.address`, {
                                    smart_count: 2,
                                })}>
                                    <TextField source="location.addressLine1"/>,
                                    <TextField source="location.postalCode" sx={{ml:0.5}} />
                                    <TextField source="location.city" sx={{ml:0.5}}/>
                                </WrapperField>
                            </SimpleShowLayout>
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleShowLayout>
                                <TextField source="email" />
                                <RoleField />
                                <TextField source="clockingCode" />
                                <TextField
                                    source="employeeNumber"
                                    label="resources.users.fields.employeeNumber"
                                />
                                <TextField source="cnasId" />
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                </Tab>
                
                {/** CONTRAT **/}
                <Tab
                    label={translate(`resources.contracts.name`, {
                        smart_count: 2,
                    })}
                >
                    <ReferenceManyField
                        label={false}
                        reference="contracts"
                        target="userId"
                        sort={{ field: 'endingDate', order: 'ASC' }}
                    >
                        <Datagrid empty={<EmptyResult />} bulkActionButtons={false}>
                            <DateField
                                source="startingDate"
                                options={{
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                }}
                            />
                            <DateField
                                source="endingDate"
                                options={{
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                }}
                            />
                            <BooleanField source="isPermanent" />
                            <BooleanField source="isActive" label="resources.contracts.fields.isActive" />
                            <ReferenceField
                                label="resources.plannings.fields.planning"
                                source="mediaId"
                                reference="medias"
                                link={false}
                            >
                                <FileField
                                    source="url"
                                    target="_blank"
                                    title="planning.pdf"
                                />
                            </ReferenceField>
                            <ShowButton />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>

                {/** CONGES **/}
                <Tab
                    label={translate(`resources.absence-types.userTitle`, {
                        smart_count: 2,
                    })}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <SimpleShowLayout>
                                <BooleanField source="isEligibleForSickChildDays" />
                                <TextField source="sickChildDaysCount" />
                                <DateField
                                    source="lastChildDateOfBirth"
                                    options={{
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }}
                                />
                                <TextField source="lastChildFirstname" />
                                <BooleanField source="hasSpouseSickChildDays" />
                                <TextField source="jhp" />
                            </SimpleShowLayout>
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleShowLayout>
                                <TextField source="cetStart" />
                                <TextField source="cetLeft" />
                                <TextField source="cetToReport" />
                                <TextField source="cetNext" />
                                <TextField source="balance" />
                                <NumberField source="annualPaidVacationCount" options={{ style: 'unit', unit: 'day' }} />
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                </Tab>

                {/** HISTORIQUE **/}
                <Tab
                    label={translate(`resources.absence-types.history.title`, {
                        smart_count: 2,
                    })}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <SimpleShowLayout>
                               ras
                            </SimpleShowLayout>
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleShowLayout>
                                ras
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                </Tab>

                {/** MOUVEMENTS **/}
                <Tab
                    label={translate(`resources.absence-types.movement.title`, {
                        smart_count: 2,
                    })}
                >
                    <React.Fragment>
                        <ArrayTitle CustomLabel="resources.absence-types.resource.PAID_LEAVE" />
                        <ArrayField source="histo.paidLeave" label={false}>
                            <Datagrid bulkActionButtons={false} sx={{
                                backgroundColor: "Lavender",
                                "& .RaDatagrid-headerCell": {
                                    backgroundColor: "slateblue",
                                },
                            }}>
                                <DateField source="date" />
                                <PlusMinusField />
                                <TextField source="amount" label="common.amount" />
                                <DateField showTime={true} showDate={false} source="startingTime" label="resources.days-schedules.fields.periods.startingTime" />
                                <DateField showTime={true} showDate={false} source="endingTime" label="resources.days-schedules.fields.periods.endingTime" />
                            </Datagrid>
                        </ArrayField>
                    </React.Fragment>

                    <ArrayTitle CustomLabel="resources.absence-types.resource.BALANCE" />
                    <ArrayField source="histo.balance" label={false}>
                        <Datagrid bulkActionButtons={false} sx={{
                            backgroundColor: "Lavender",
                            "& .RaDatagrid-headerCell": {
                                backgroundColor: "slateblue",
                            },
                        }}>
                            <DateField source="date" />
                            <PlusMinusField />
                            <TextField source="amount" label="common.amount" />
                            <DateField showTime={true} showDate={false} source="startingTime" label="resources.days-schedules.fields.periods.startingTime" />
                            <DateField showTime={true} showDate={false} source="endingTime" label="resources.days-schedules.fields.periods.endingTime" />
                        </Datagrid>
                    </ArrayField>

                    <ArrayTitle CustomLabel="resources.absence-types.resource.EXCEPTIONAL_HOUR" />
                    <ArrayField source="histo.exceptionnalHour" label={false}>
                        <Datagrid bulkActionButtons={false} sx={{
                            backgroundColor: "Lavender",
                            "& .RaDatagrid-headerCell": {
                                backgroundColor: "slateblue",
                            },
                        }}>
                            <DateField source="date" />
                            <PlusMinusField />
                            <TextField source="amount" label="common.amount" />
                            <DateField showTime={true} showDate={false} source="startingTime" label="resources.days-schedules.fields.periods.startingTime" />
                            <DateField showTime={true} showDate={false} source="endingTime" label="resources.days-schedules.fields.periods.endingTime" />
                        </Datagrid>
                    </ArrayField>

                    <ArrayTitle CustomLabel="resources.absence-types.resource.GRANTED_LEAVE" />
                    <ArrayField source="histo.grantedLeave" label={false}>
                        <Datagrid bulkActionButtons={false} sx={{
                            backgroundColor: "Lavender",
                            "& .RaDatagrid-headerCell": {
                                backgroundColor: "slateblue",
                            },
                        }}>
                            <DateField source="date" />
                            <TextField source="reason" label="resources.absence-types.reasons.label" />
                            <TextField source="amount" label="common.amount" />
                            <DateField showTime={true} showDate={false} source="startingTime" label="resources.days-schedules.fields.periods.startingTime" />
                            <DateField showTime={true} showDate={false} source="endingTime" label="resources.days-schedules.fields.periods.endingTime" />
                        </Datagrid>
                    </ArrayField>

                    <ArrayTitle CustomLabel="resources.absence-types.resource.SICK_CHILD_DAY" />
                    <ArrayField source="histo.sickChildDay" label={false}>
                        <Datagrid bulkActionButtons={false} sx={{
                            backgroundColor: "Lavender",
                            "& .RaDatagrid-headerCell": {
                                backgroundColor: "slateblue",
                            },
                        }}>
                            <DateField source="date" />
                            <TextField source="amount" label="common.amount" />
                            <DateField showTime={true} showDate={false} source="startingTime" label="resources.days-schedules.fields.periods.startingTime" />
                            <DateField showTime={true} showDate={false} source="endingTime" label="resources.days-schedules.fields.periods.endingTime" />
                        </Datagrid>
                    </ArrayField>

                    <ArrayTitle CustomLabel="resources.absence-types.resource.PLANNING_CHANGE" />
                    <ArrayField source="histo.planningChange" label={false}>
                        <Datagrid bulkActionButtons={false} sx={{
                            backgroundColor: "Lavender",
                            "& .RaDatagrid-headerCell": {
                                backgroundColor: "slateblue",
                            },
                        }}>
                            <DateField source="date" />
                            <TextField source="amount" label="common.amount" />
                            <DateField showTime={true} showDate={false} source="startingTime" label="resources.days-schedules.fields.periods.startingTime" />
                            <DateField showTime={true} showDate={false} source="endingTime" label="resources.days-schedules.fields.periods.endingTime" />
                        </Datagrid>
                    </ArrayField>

                    <ArrayTitle CustomLabel="resources.absence-types.resource.CET" />
                    <ArrayField source="histo.cet" label={false}>
                        <Datagrid bulkActionButtons={false} sx={{
                            backgroundColor: "Lavender",
                            "& .RaDatagrid-headerCell": {
                                backgroundColor: "slateblue",
                            },
                        }}>
                            <DateField source="date" />
                            <TextField source="amount" label="common.amount" />
                            <DateField showTime={true} showDate={false} source="startingTime" label="resources.days-schedules.fields.periods.startingTime" />
                            <DateField showTime={true} showDate={false} source="endingTime" label="resources.days-schedules.fields.periods.endingTime" />
                        </Datagrid>
                    </ArrayField>
                </Tab>

                {/** POINTAGES **/}
                <Tab
                    label={translate(`resources.clockings.name`, {
                        smart_count: 2,
                    })}
                >
                    <div style={{position: "relative"}}>
                        <Button style={{position: "absolute", top: -50, right: 0}} onClick={() => redirect(`/clockings?displayedFilters={"userId":true}&filter={"userId":"${userId}"}&order=DESC&page=1&perPage=10&sort=date`)} >
                            Exporter
                        </Button>
                    </div>
                    <ReferenceManyField
                        label={false}
                        reference="clockings"
                        target="userId"
                        sort={{ field: 'date', order: 'DESC' }}
                    >
                        <Datagrid empty={<EmptyResult />} bulkActionButtons={false}>
                            <DateField
                                source="date"
                                options={{
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                }}
                            />
                            <InOrOutField />
                            <StateField />
                            <ReferenceField source="deviceId" reference="devices" link="show">
                                <ChipField source="name" />
                            </ReferenceField>
                            <ReferenceField source="siteId" reference="sites" link="show">
                                <ChipField source="name" />
                            </ReferenceField>
                            <ShowButton />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
