import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    TextInput,
    Create,
    SimpleForm,
    DateInput,
    SelectInput,
    BooleanInput,
    NumberInput,
    useTranslate,
    FormDataConsumer,
    ReferenceInput,
} from 'react-admin';
import { Box, Grid } from '@mui/material';
import SectionTitle from '../../components/SectionTitle';
import * as yup from 'yup';
import { RoleEnum } from '../../constants';
import { UserRecord } from '../../types';
import { ReplaceEmptyStringsByNullInObjectRecursively } from '../../utils/form';

export const UserCreate = () => {
    return (
        <Create redirect="list" transform={userTransform}>
            <UserForm />
        </Create>
    );
};

export const userTransform = (data: UserRecord) => ({
    ...ReplaceEmptyStringsByNullInObjectRecursively(data),
    // lastChildDateOfBirth: data.lastChildDateOfBirth
    //     ? `${data.lastChildDateOfBirth}T00:00:00.000Z`
    //     : null,
});

// export const userTransform = (data: UserRecord) => ({
//     ...data,
//     lastChildDateOfBirth: data.lastChildDateOfBirth
//         ? `${data.lastChildDateOfBirth}T00:00:00.000Z`
//         : null,
// });

export const userValidationSchema = yup
    .object()
    .shape({
        gender: yup.mixed().oneOf(['MR', 'MME']).required(),
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().email(),
    })
    .required();

export const UserForm = () => {
    const translate = useTranslate();

    return (
        <SimpleForm
            resolver={yupResolver(userValidationSchema)}
            sx={{ maxWidth: 500 }}
        >

            <BooleanInput
                source="isActive"
                label="resources.users.fields.isActive"
                defaultValue={false}
            />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        label={'resources.users.fields.role.label'}
                        source="role"
                        defaultValue={RoleEnum.AGENT}
                        choices={[
                            {
                                id: RoleEnum.SUPER_ADMIN,
                                name: `resources.users.fields.role.${RoleEnum.SUPER_ADMIN}`,
                            },
                            {
                                id: RoleEnum.ABSENCES_MANAGER,
                                name: `resources.users.fields.role.${RoleEnum.ABSENCES_MANAGER}`,
                            },
                            {
                                id: RoleEnum.CATEGORY_MANAGER,
                                name: `resources.users.fields.role.${RoleEnum.CATEGORY_MANAGER}`,
                            },
                            {
                                id: RoleEnum.AGENT,
                                name: `resources.users.fields.role.${RoleEnum.AGENT}`,
                            },
                        ]}
                    />
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.role === RoleEnum.CATEGORY_MANAGER && (
                                <ReferenceInput
                                    source="managedCategoryId"
                                    reference="categories"
                                >
                                    <SelectInput
                                        label="resources.users.fields.managedCategoryId"
                                        optionText="name"
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>
                </Grid>

                <Grid item xs={6}>
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <ReferenceInput source="categoryId" reference="categories">
                            <SelectInput
                                label="resources.users.fields.categoryId"
                                optionText="name"
                            />
                        </ReferenceInput>
                    </Box>
                </Grid>
            </Grid>

            <SectionTitle label="resources.users.fieldGroups.identity" />
            <SelectInput
                label={'resources.users.fields.gender.title'}
                source="gender"
                choices={[
                    {
                        id: 'MR',
                        name: 'resources.users.fields.gender.male',
                    },
                    {
                        id: 'MME',
                        name: 'resources.users.fields.gender.female',
                    },
                ]}
            />
            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput
                        source="firstname"
                        label="resources.users.fields.firstname"
                        fullWidth
                        isRequired
                        helperText={false}
                    />
                </Box>
                <Box flex={2}>
                    <TextInput
                        source="lastname"
                        label="resources.users.fields.lastname"
                        fullWidth
                        isRequired
                        helperText={false}
                    />
                </Box>
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }} sx={{mt:2}}>
                <TextInput
                    source="email"
                    label="resources.users.fields.email"
                    isRequired
                />
                <TextInput
                    source="phoneNumber"
                    label="resources.users.fields.phoneNumber"
                    isRequired
                />
            </Box>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput
                        source="employeeNumber"
                        label="resources.users.fields.employeeNumber"
                        isRequired
                        fullWidth
                    />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <TextInput
                        source="cnasId"
                        label="resources.users.fields.cnasId"
                        isRequired
                        fullWidth
                    />
                </Box>
            </Box>

            <TextInput
                source="clockingCode"
                label="resources.users.fields.clockingCode"
                fullWidth
                helperText={'resources.users.fields.clockingCodeHelper'}
            />
            
            <SectionTitle label="resources.users.fieldGroups.address" />
            <Box display={{ xs: 'block', sm: 'flex'}} sx={{mb:2, mt:2}}>
                
                <TextInput
                    source="location.addressLine1"
                    label="resources.users.fields.location.addressLine1"
                    multiline
                    fullWidth
                    isRequired
                    helperText={false}
                />
                <TextInput
                    source="location.addressLine2"
                    label="resources.users.fields.location.addressLine2"
                    multiline
                    helperText={false}
                />
            </Box>
            <Box display={{ xs: 'block', sm: 'flex' }}>
                    <TextInput
                        source="location.city"
                        label="resources.users.fields.location.city"
                        isRequired
                        helperText={false}
                    />
                    <TextInput
                        source="location.postalCode"
                        label="resources.users.fields.location.postalCode"
                        isRequired
                        helperText={false}
                    />
            </Box>
            <Box display={{ xs: 'block', sm: 'flex' }}>
                    <SelectInput
                        isRequired
                        defaultValue="FR"
                        source="location.countryCode"
                        label="resources.users.fields.location.countryCode"
                        choices={[
                            {
                                id: 'FR',
                                name: translate('common.countries.FR'),
                            },
                        ]}
                    />
            </Box>
            <Box>
                <SectionTitle label="resources.users.fieldGroups.cet" />
                <NumberInput
                    source="cetStart"
                    label="resources.users.fields.cetStart"
                    defaultValue={0}
                    min={0}
                />
                <NumberInput
                    source="cetLeft"
                    label="resources.users.fields.cetLeft"
                    defaultValue={0}
                    min={0}
                />
                <NumberInput
                    source="cetToReport"
                    label="resources.users.fields.cetToReport"
                    defaultValue={0}
                    min={0}
                />
                <NumberInput
                    source="cetNext"
                    label="resources.users.fields.cetNext"
                    defaultValue={0}
                    min={0}
                />
            </Box>

            <Box>
                <SectionTitle label="resources.users.fieldGroups.jhp" />
                <NumberInput
                    source="jhp"
                    label="resources.users.fields.jhp"
                    defaultValue={0}
                    min={0}
                />
            </Box>

            <Box>
                <SectionTitle label="resources.users.fieldGroups.sickChildDays" />
                <BooleanInput
                    source="isEligibleForSickChildDays"
                    label="resources.users.fields.isEligibleForSickChildDays"
                    defaultValue={false}
                />
                <FormDataConsumer>
                    {({ formData }) =>
                        formData.isEligibleForSickChildDays === true && (
                            <>
                                <Box
                                    display={{
                                        xs: 'block',
                                        sm: 'flex',
                                        width: '100%',
                                    }}
                                >
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <TextInput
                                            source="lastChildFirstname"
                                            label="resources.users.fields.lastChildFirstname"
                                            fullWidth
                                        />
                                    </Box>
                                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                        <DateInput
                                            source="lastChildDateOfBirth"
                                            defaultValue={new Date()}
                                            label="resources.users.fields.lastChildDateOfBirth"
                                            fullWidth
                                        />
                                    </Box>
                                </Box>
                                <BooleanInput
                                    source="hasSpouseSickChildDays"
                                    label="resources.users.fields.hasSpouseSickChildDays"
                                    defaultValue={false}
                                />
                                <NumberInput
                                    source="sickChildDaysCount"
                                    label="resources.users.fields.sickChildDaysCount"
                                />
                            </>
                        )
                    }
                </FormDataConsumer>
            </Box>
        </SimpleForm>
    );
};
