import React from 'react';
import {
    Create,
    BooleanInput,
    SimpleForm,
    TextInput,
    DateInput,
    SelectInput,
    ReferenceInput,
    NumberInput,
    useTranslate,
    TextField, FormDataConsumer, AutocompleteInput,
} from 'react-admin';
import { Box, Grid } from '@mui/material';
import { ReplaceEmptyStringsByNullInObjectRecursively } from '../../utils/form';
import InputGroupTitle from '../../components/InputGroupTitle';
import Separator from '../../components/Separator';


export const ContractCreate = () => {
    return (
        <Create
            title={<TextField source="name" />}
            transform={ReplaceEmptyStringsByNullInObjectRecursively}
            redirect="list"
        >
            <ContractForm />
        </Create>
    );
};

export const ContractForm = () => {
    const translate = useTranslate();

    return (
        <SimpleForm>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <ReferenceInput source="userId" reference="users">
                        <AutocompleteInput
                            label="resources.contracts.fields.userFullname"
                            optionText="fullname"
                            isRequired
                            fullWidth
                        />
                    </ReferenceInput>

                    <ReferenceInput source="categoryId" reference="categories">
                        <SelectInput
                            label="resources.users.fields.categoryId"
                            optionText="name"
                            sx={{mr:1}}
                        />
                    </ReferenceInput>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.categoryId && (
                                <ReferenceInput
                                    source="planningId"
                                    reference="plannings"
                                    filter={{
                                        categoryId: formData.categoryId,
                                        state: "VALIDATED",
                                    }}
                                >
                                    <SelectInput
                                        label="resources.plannings.fields.name"
                                        optionText="name"
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>
                </Grid>
                <Grid item xs={6}>
                    <DateInput
                        source="startingDate"
                        label="resources.contracts.fields.startingDate"
                        sx={{mt:1}}
                    />
                    <DateInput
                        source="endingDate"
                        label="resources.contracts.fields.endingDate"
                        sx={{ml:1, mt:1}}
                    />
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <BooleanInput
                            label="resources.contracts.fields.isPermanent"
                            source="isPermanent"
                            defaultValue={true}
                        />
                        <BooleanInput
                            label="resources.contracts.fields.isActive"
                            source="isActive"
                            defaultValue={true}
                            sx={{ml:1}}
                        />
                    </Box>
                </Grid>
            </Grid>

            <TextInput
                label="resources.contracts.fields.details"
                source="details"
                multiline
                fullWidth
            />

            <Separator />

            <Grid container spacing={1} sx={{mt:5}}>
                <Grid item xs={3} sx={{borderRight:1}} textAlign="center">
                <InputGroupTitle CustomLabel="resources.users.fields.weeklyWorkingTime" />
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%', justifyContent:"center"}} sx={{pt:1}}>
                        <NumberInput
                            source="weeklyWorkingTimeHours"
                            label="common.unit.hour"
                            min={0}
                            defaultValue="0"
                            sx={{width:90, mr:1}}
                        />
                        <NumberInput
                            source="weeklyWorkingTimeMinutes"
                            label="common.unit.minute"
                            min={0}
                            max={59}
                            defaultValue="0"
                            sx={{width:80}}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{borderRight:1}} textAlign="center">
                    <InputGroupTitle CustomLabel="resources.users.fields.weeklyWorkedDaysCount" />
                    <NumberInput
                        source="weeklyWorkedDaysCount"
                        label="common.unit.day"
                        min={0}
                        defaultValue="0"
                        sx={{width:80, }}
                    />
                </Grid>
                <Grid item xs={2} sx={{borderRight:1}} textAlign="center">
                    <InputGroupTitle CustomLabel="resources.users.fields.annualWorkingTime" />
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%', justifyContent:"center"}}>
                        <NumberInput
                            source="annualWorkingTimeHours"
                            label="common.unit.hour"
                            min={0}
                            defaultValue="0"
                            sx={{width:90, mr:1}}
                        />
                        <NumberInput
                            source="annualWorkingTimeMinutes"
                            label="common.unit.minute"
                            min={0}
                            max={59}
                            defaultValue="0"
                            sx={{width:80}}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{borderRight:1}} textAlign="center">
                    <InputGroupTitle CustomLabel="resources.users.fields.annualPaidVacationCount" />
                    <NumberInput
                        source="annualPaidVacationCount"
                        label="common.unit.hour"
                        min={0}
                        defaultValue="0"
                        sx={{width:90}}
                    />
                </Grid>
                <Grid item xs={3} textAlign="center">
                    <InputGroupTitle CustomLabel="resources.users.fields.exceptionalHoursCount" />
                    <NumberInput
                        source="exceptionalHoursCount"
                        label="common.unit.hour"
                        min={0}
                        defaultValue="0"
                        sx={{width:90}}
                    />
                </Grid>
            </Grid>
        </SimpleForm>
    );
};
