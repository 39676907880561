import {
    FieldProps,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { UserRecord } from '../../../types';
import React from 'react';

export const InOrOutField = (props: FieldProps<UserRecord>) => {
    const record = useRecordContext<UserRecord>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate(`resources.clockings.inOrOut.${record.inOrOut}`)}
        </span>
    ) : null;
};

InOrOutField.defaultProps = { label: 'resources.clockings.inOrOut.label' };
