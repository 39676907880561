import React from 'react';
import { NumberFieldProps, useRecordContext } from 'react-admin';
import { formatDuration } from 'date-fns';
import { fr } from 'date-fns/locale';

export const DurationField = ({ source }: NumberFieldProps) => {
    const record = useRecordContext();
    if (!record || !source) {
        return null;
    }

    return (
        <span>
            {record && record[source] > 0
                ? formatDuration(
                      {
                          hours: Math.trunc(record[source] / 60),
                          minutes: record[source] % 60,
                      },
                      {
                          locale: fr,
                      }
                  )
                : 0}
        </span>
    );
};
