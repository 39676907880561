import { Translate } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    useTranslate,
    DateField,
    BooleanField,
    NumberField,
    WrapperField,
    FileField
} from 'react-admin';

export const ContractShow = () => {
    const t = useTranslate();

    return (
        <Show title={<ReferenceField
            source="userId"
            reference="users"
            link={false}
        ><TextField source="fullname" />
        </ReferenceField>}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <WrapperField label={t(`resources.contracts.fields.userFullname`, {
                            smart_count: 2,
                        })}>
                            <ReferenceField
                                label={t('resources.users.fields.firstname')}
                                source="userId"
                                reference="users"
                                link={false}
                            >
                                <TextField source="firstname" sx={{mr:0.5}} />
                            </ReferenceField>
                            <ReferenceField
                                label={t('resources.users.fields.lastname')}
                                source="userId"
                                reference="users"
                                link={false}
                            >
                                <TextField source="lastname" />
                            </ReferenceField>
                        </WrapperField>
                    </SimpleShowLayout>

                    <SimpleShowLayout>    
                        <DateField
                            source="startingDate"
                            options={{
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            }}
                        />
                        <DateField
                            source="endingDate"
                            options={{
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            }}
                        />
                        <BooleanField source="isPermanent" />
                        <BooleanField source="isActive" />
                        <TextField source="details" />
                    </SimpleShowLayout>
                </Grid>

                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <TextField source="formattedAnnualWorkingTime" label={t(`resources.users.fields.annualWorkingTime`)} />
                        <TextField source="formattedWeeklyWorkingTime" label={t(`resources.users.fields.weeklyWorkingTime`)} />
                        <NumberField
                            source="weeklyWorkedDaysCount"
                            label="resources.users.fields.weeklyWorkedDaysCount"
                            options={{ style: 'unit', unit: 'day' }}
                        />
                        <NumberField source="annualPaidVacationCount" options={{ style: 'unit', unit: 'hour' }} />
                        <TextField source="balance" />
                    </SimpleShowLayout>
                    <SimpleShowLayout>
                        <ReferenceField
                        label={t('resources.plannings.fields.planning')}
                        source="mediaId"
                        reference="medias"
                        link={false}
                        >
                            <FileField
                                source="url"
                                target="_blank"
                                title="planning.pdf"
                            />
                        </ReferenceField>
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    );
};
