import { TranslationMessages } from 'react-admin';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    login: {
        passwordForgotten: "Mot de passe oublié ?"
    },
    validation: {
        required: 'Ce champs est requis',
        endingTimeAfterStartingTime: "Ne peut pas être avant l'heure de début",
    },
    updatePassword: {
        password: 'Nouveau mot de passe',
        confirmPassword: 'Confirmer votre mot de passe',
        submit: 'Définir votre mot de passe'
    },
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        filter: 'Filtré par',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscure',
        },
        subMenu: {
            planification: 'Planification',
            settings: 'Paramétrage',
            users: 'Gestion des Agents',
        },
        menu: {
            absences: 'Absence',
            users: 'Agent',
            sites: 'Site',
            absenceTypes: "Type d'absence",
            devices: 'Tablette',
            categories: 'Service',
            clockings: 'Pointage',
            cycles: 'Cycle',
            contracts: 'Contrat',
            daysSchedules: 'Journée Type',
            calendars: 'Calendrier',
            plannings: 'Planning',
            reconciliations: 'Réconciliation',
        },
    },
    common: {
        countries: {
            FR: 'France',
        },
        emptyResult: 'Aucun résultat',
        weekDays: {
            0: 'dimanche',
            1: 'lundi',
            2: 'mardi',
            3: 'mercredi',
            4: 'jeudi',
            5: 'vendredi',
            6: 'samedi',
        },
        unit: {
            hour: 'heure',
            minute: 'min',
            day: 'Jour',
        },
        amount: 'Quantité',
    },
    resources: {
        absences: {
            form: {
                startingDate: 'Date de début',
                endingDate: 'Date de fin',
                timeTitle: 'Heures de début et fin',
                startingTime: 'Début',
                endingTime: 'Fin',
                userFullname: 'Agent',
                allDay: 'Journée complète',
                absenceType: 'Type d\'absence',
                comment: 'Commentaire',
                amount: 'Quantité',
            },
            state: {
                label: "Etat",
                AWAITING_VALIDATION: 'En attente de validation',
                VALIDATED: 'Validé',
                REJECTED: 'Refusé',
            },
            transitions: {
                to_validated: {
                    label: 'Valider',
                    success: 'bravo',
                    error: 'pas bravo',
                },
                to_rejected: {
                    label: 'Refuser',
                    success: 'bravo',
                    error: 'pas bravo',
                },
            },
        },
        category: {
            name: 'Nom du service',
            iconName: 'Icone référence',
        },
        calendars: {
            name: 'Calendrier |||| Calendriers',
        },
        reconciliations: {
            name: 'Réconciliation |||| Réconciliations',
            infoAgent: 'Informations de l\'agent',
            clockingField: 'Pointages',
            planningEvent: 'Planning',
            options: 'Options disponibles',
            plusMinus : {
                label : "Débit/Crédit",
                plus: "Crédit",
                minus: "Débit",
            },
        },
        contracts: {
            name: 'Contrat |||| Contrats',
            add: 'Ajouter un contrat',
            fields: {
                userFullname: "Nom de l'agent",
                startingDate: 'Date de début',
                endingDate: 'Date de fin',
                categories: 'Categorie',
                isPermanent: 'Titulaire',
                details: 'Commentaires',
                isActive: 'Actif',
                annualPaidVacationCount: 'C.A',
            },
        },
        clockings: {
            name: 'Pointage |||| Pointages',
            fields: {
                picture: 'Photo',
                isEntry: 'Sortie / Entrée',
                user: 'Agent',
                site: 'site'
            },
            filter: {
                gte: 'Emis depuis',
                lte: 'Emis avant',
            },
            inOrOut: {
                label: 'Entrée/Sortie',
                in: 'Entrée',
                out: 'Sortie',
                na: 'N/A',
            },
            state: {
                label: 'Etat',
                AWAITING_CHECK: 'En attente de validation',
                CHECKED: 'Validé',
                ERROR: 'En erreur',
                UNDEFINED: 'Indéfinis',
                RESOLVED: 'Résolu',
                MANUAL: 'Manuel',
                IGNORED: 'Ignoré',
            },
        },
        'days-schedules': {
            name: 'Journée Type |||| Journées Type',
            show: {
                title: 'Journée type',
                titles: 'Journées type',
            },
            fields: {
                name: 'Nom de la journée type',
                totalMinutesOfWork: 'Temps de travail théorique',
                categoryId: 'Service',
                periods: {
                    label: 'Périodes de travail',
                    name: 'Nom de la période',
                    startingTime: 'Début',
                    endingTime: 'Fin',
                },
            },
        },
        devices: {
            name: 'Tablette |||| Tablettes',
            fields: {
                name: "Nom de la tablette",
                synchronizationCode: 'Code de synchronization',
                isSynchronized: 'Synchronisée',
                site: 'Site',
                lastPingAt: 'Dernier mouvement',
                appVersion: 'Version de l\'app',
                isActive: 'Activée'
            },
        },
        cycles: {
            name: 'Cycle |||| Cycles',
            fields: {
                name: 'Nom du cycle',
                order: 'Ordre',
                categoryId: 'Service',
                daySchedule: 'Journée type',
            },
        },
        plannings: {
            name: 'Planning |||| Plannings',
            fields: {
                startingDate: 'Date de début',
                endingDate: 'Date de fin',
                media: "Fichiers",
                planning: "Planning",
                name: 'Nom du planning',
                categoryId: 'Service',
                cycles: {
                    label: 'Cycles',
                    startingDate: 'Date de début',
                    endingDate: 'Date de fin',
                    cycleId: 'Cycle',
                },
                publicHolidays: {
                    label: 'Jours fériés',
                },
                closingPeriods: {
                    label: 'Périodes de fermeture',
                    name: 'Nom',
                    startingDate: 'Date de début',
                    endingDate: 'Date de fin',
                    isPaidLeave: 'Congés Annuels',
                },
                specificDays: {
                    label: 'Jours spécifiques',
                    date: 'Date',
                    dayScheduleId: 'Journée type',
                },
                state: {
                    label: "Etat",
                    DRAFT: 'Brouillon',
                    AWAITING_VALIDATION: 'En attente de validation',
                    VALIDATED: 'Validé',
                },
            },
            balancePreview: {
                title: 'Prévisualisation',
            },
            transitions: {
                to_draft: {
                    label: 'Repasser en brouillon',
                    success: 'bravo',
                    error: 'pas bravo',
                },
                awaiting: {
                    label: 'Demande de validation',
                    success: 'bravo',
                    error: 'pas bravo',
                },
                to_valide: {
                    label: 'Valider',
                    success: 'bravo',
                    error: 'pas bravo',
                },
                reject: {
                    label: 'Refuser',
                    success: 'bravo',
                    error: 'pas bravo',
                },
            },
        },
        users: {
            name: 'Agent |||| Agents',
            fieldGroups: {
                identity: 'Identité',
                theoreticalWorkingTime: 'Temps de travail théorique',
                address: 'Adresse',
                cet: 'Compte Epargne Temps',
                jhp: 'J.H.P',
                sickChildDays: 'Jours enfants malade',
                role: 'Rôle',
            },
            fields: {
                firstname: 'Prénom',
                lastname: 'Nom',
                email: 'Email',
                fullname: 'Nom complet',
                password: 'Mot de passe',
                clockingCode: 'Code de pointage',
                clockingCodeHelper: 'Si vide, sera crée automatiquement',
                role: {
                    label: 'Rôle',
                    SUPER_ADMIN: 'D.G.S',
                    ADMIN: 'Administrateur',
                    ABSENCES_MANAGER: 'Gestionnaire absences',
                    CATEGORY_MANAGER: 'Chef de service',
                    AGENT: 'Agent',
                },
                categoryId: 'Service',
                managedCategoryId: 'Service géré',
                cnasId: 'Affiliation CNAS',
                location: {
                    addressLine1: 'Adresse',
                    addressLine2: "Complément d'adresse",
                    city: 'Ville',
                    postalCode: 'Code postal',
                    countryCode: 'Pays',
                },
                weeklyWorkingTime: 'Temps de travail hebdo',
                weeklyWorkedDaysCount: 'Nombre de jours travaillés hebdo',
                annualWorkingTime: 'Temps de travail annuel',
                annualPaidVacationCount: 'C.A',
                cetStart: 'C.E.T disponible au 1er janvier',
                cetLeft: 'C.E.T Restant',
                cetToReport: 'C.E.T à reporter',
                cetNext: 'C.E.T disponible l\'année prochaine',
                jhp: 'J.H.P',
                isEligibleForSickChildDays: 'Eligible aux jours enfants malade',
                lastChildFirstname: 'Prénom du dernier enfant ',
                lastChildDateOfBirth: 'Date de naissance du dernier enfant',
                hasSpouseSickChildDays: 'Conjoint jours enfant malade',
                sickChildDaysCount: 'Jours enfant malade ',
                exceptionalHoursCount: "Nombre d'heures exceptionnelles du trimestre en cours",
                gender: {
                    title: 'Civilité',
                    male: 'Monsieur',
                    female: 'Madame',
                },
                employeeNumber: "Numéro d'employé",
                phoneNumber: 'Numero de téléphone',
                isActive: 'Agent actif',
            },
        },
        sites: {
            name: 'Site |||| Sites',
            fieldGroups: {
                address: 'Adresse',
            },
            fields: {
                name: 'Nom du site',
                category: 'Service',
                location: {
                    addressLine1: 'Adresse',
                    addressLine2: "Complément d'adresse",
                    city: 'Ville',
                    postalCode: 'Code postal',
                    countryCode: 'Pays',
                },
            },
        },
        'absence-types': {
            name: "Type d'absence |||| Types d'absence",
            userTitle: "Congés",
            page: {
                create: {
                    title: "Création d'un type d'absence",
                },
            },
            fields: {
                name: "Nom",
                resource: 'Ressource',
                unit: 'Unité',
            },
            resource: {
                PAID_LEAVE: 'Congé Annuel',
                BALANCE: 'Balance',
                EXCEPTIONAL_HOUR: 'Heure exceptionnelle',
                GRANTED_LEAVE: 'Absence autorisée',
                SICK_CHILD_DAY: 'Jour enfant malade',
                PLANNING_CHANGE: 'Changement de planning',
                CET: 'C.E.T',
                JHP: 'J.H.P',
            },
            unit: {
                DAYS: 'Jours',
                MINUTES: 'Minutes',
            },
            reasons: {
                label: "Raison de l'absence"
            },
            history: {
                title: 'Historique des absences',
            },
            movement: {
                title: 'Mouvements des absences'
            },
        },
    },
};

export default customFrenchMessages;
