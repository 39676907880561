import React, { FC, ReactElement } from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ArrayField,
    Datagrid,
    ReferenceField,
    DateField,
} from 'react-admin';

export const DayScheduleShow = () => (
    <Show title={<TextField source="name" />}>
        <SimpleShowLayout>
            <TextField source="name" />
            <ReferenceField source="categoryId" reference="categories">
                <TextField source="name" />
            </ReferenceField>
            <ArrayField
                label={false}
                source="periods"
            >
                <Datagrid bulkActionButtons={false}>
                    <TextField source="name" label="resources.days-schedules.fields.periods.label" />
                    <TextField source="startingTime" label="resources.days-schedules.fields.periods.startingTime" />
                    <TextField source="endingTime" label="resources.days-schedules.fields.periods.endingTime" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);
