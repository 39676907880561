import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import { CycleList } from './CycleList';
import { CycleShow } from './CycleShow';
import { CycleCreate } from './CycleCreate';

export default {
    create: CycleCreate,
    list: CycleList,
    show: CycleShow,
    icon: EventRepeatIcon,
};
