import React from 'react';
import { Show, TextField, SimpleShowLayout } from 'react-admin';

export const AbsenceTypeShow = () => {
    return (
        <Show title={<TextField source="name" />}>
            <SimpleShowLayout>
                <TextField source="name" />
            </SimpleShowLayout>
            <SimpleShowLayout>
                <TextField source="description" />
            </SimpleShowLayout>
        </Show>
    );
};
