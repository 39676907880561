import React, { ReactElement } from 'react';
import {
    Datagrid,
    TextField,
    List
} from 'react-admin';

export const AbsenceTypeList = () => {

    return (
        <List 
            pagination={false}
            sort={{ field: 'name', order: 'ASC' }}
        >
            <Datagrid bulkActionButtons={false} sx={{ ".RaDatagrid-headerCell": {
                backgroundColor: "#6E77DA",
                fontSize: 16,
                color: "white"
            }}}>
                <TextField source="name" />
                <TextField source="unitLibelle" sortable={false} label="resources.absence-types.fields.unit" />
                <TextField source="description" sortable={false} />
            </Datagrid>
        </List>
    );
};
