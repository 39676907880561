import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ArrayField,
    Datagrid,
    ReferenceField,
    useTranslate,
    UrlField,
    useRecordContext,
    useDataProvider,
} from 'react-admin';
import { DayStringField } from '../../common/fields/dayStringField';

const BookAuthor = () => {
    const record = useRecordContext();

    if (record.dayScheduleId == null) {
        return (
            <>
                <span>Repos</span>
            </>
        );
    } else {
        return (
            <>
                <ReferenceField
                    source="dayScheduleId"
                    reference="days-schedules"
                    link="show"
                >
                    <UrlField source="name" />
                </ReferenceField>
            </>
        );
    }
};



const GetColorCycleInADiv = () => {
    const dataProvider = useRecordContext();

    return (
        <>
            <div
                style={{
                    backgroundColor: dataProvider.color,
                    width: '50px',
                    height: '30px',
                    display: 'inline-block',
                    marginLeft: '5px',
                    marginRight: '10px'
                }}
            ></div>
        </>
    );
};

export default GetColorCycleInADiv;


export const CycleShow = () => {
    const translate = useTranslate();

    return (
        <Show title={<TextField source="name" />}>
            <SimpleShowLayout>
                <TextField source="name" />
                <GetColorCycleInADiv />
                <ArrayField source="daySchedules" label={translate('resources.days-schedules.show.titles')}>
                    <Datagrid bulkActionButtons={false}>
                        <TextField
                            label={translate('resources.cycles.fields.order')}
                            source="position"
                        />
                        <DayStringField source="position" />
                        <BookAuthor />
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>
    );
};
