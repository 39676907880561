import decodeJwt from 'jwt-decode';

type Login = {
    username: string;
    password: string;
};

type Status = {
    status: number;
};

export const cleanLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
};

export default {
    // called when the user attempts to log in
    login: ({username, password}: Login) => {
        const request = new Request(
            `${process.env.REACT_APP_API_URL}/authentication/login`,
            {
                method: 'POST',
                body: JSON.stringify({username, password}),
                headers: new Headers({'Content-Type': 'application/json'}),
            }
        );
        return fetch(request)
            .then(response => {
                if (response.status < 200 || (response.status >= 300 && response.status !== 301)) {
                    throw new Error(response.statusText);
                }
                return response.json().then(({access_token}) => {
                    const decodedToken: any = decodeJwt(access_token);
                    localStorage.setItem('token', access_token);
                    localStorage.setItem('user', JSON.stringify({
                        id: decodedToken.sub,
                        fullName: decodedToken.username,
                        categoryId: decodedToken.categoryId,
                        managedCategoryId: decodedToken.managedCategoryId
                    }));
                    localStorage.setItem(
                        'role',
                        decodedToken.role[0]
                    );
                    if (response.status === 301) {
                        return Promise.resolve({redirectTo: '/set-password'});
                    }
                })
            })
            .catch(() => {
                throw new Error('Network Error');
            });
    },
    // called when the API returns an error
    checkError: ({status}: Status) => {
        if (status === 401 || status === 403) {
            cleanLocalStorage();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    logout: () => {
        cleanLocalStorage();
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const userStringified = localStorage.getItem('user') ?? '';
            if (userStringified) {
                const {id, fullName, categoryId, managedCategoryId} = JSON.parse(userStringified);
                return Promise.resolve({
                    id,
                    fullName,
                    categoryId,
                    managedCategoryId
                });
            }

            return Promise.reject();
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: () => {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    },
};
