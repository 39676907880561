import React from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    AutocompleteInput,
    BooleanInput,
    Create,
    DateTimeInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    useTranslate,
} from 'react-admin';
import * as yup from 'yup';
import {ClockingRecord} from '../../types';
import {ReplaceEmptyStringsByNullInObjectRecursively} from '../../utils/form';

export const ClockingCreate = () => {
    return (
        <Create redirect="list" transform={clockingTransform}>
            <UserForm />
        </Create>
    );
};

export const clockingTransform = (data: ClockingRecord) => ({
    ...ReplaceEmptyStringsByNullInObjectRecursively(data),
});


export const clockingValidationSchema = yup
    .object()
    .shape({
        userId: yup.string(),
        date: yup.date().required(),
        siteId: yup.string().required(),
        isEntry: yup.string().required(),
    })
    .required();

export const UserForm = () => {
    const translate = useTranslate();

    return (
        <SimpleForm
            resolver={yupResolver(clockingValidationSchema)}
            sx={{ maxWidth: 500 }}
        >

            <BooleanInput
                source="isEntry"
                label="resources.clockings.fields.isEntry"
                defaultValue={false}
            />

            <ReferenceInput source="userId" reference="users" label="resources.clockings.fields.user">
                <AutocompleteInput
                    label="resources.clockings.fields.user"
                    optionText="fullname"
                />
            </ReferenceInput>

            <ReferenceInput source="siteId" reference="sites">
                <SelectInput
                    optionText="name"
                    isRequired
                    label="resources.clockings.fields.site"
                />
            </ReferenceInput>

            <DateTimeInput
                source="date"
                defaultValue={new Date()}
            />
        </SimpleForm>
    );
};
