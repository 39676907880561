import React from 'react';
import {
    TextInput,
    SimpleForm,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    Create,
    TextField,
    ReferenceInput,
} from 'react-admin';
import { ReplaceEmptyStringsByNullInObjectRecursively } from '../../utils/form';
import { Grid } from '@mui/material';
import { string } from 'yup';

export const DayScheduleCreate = () => {
    return (
        <Create
            title={<TextField source="name" />}
            transform={ReplaceEmptyStringsByNullInObjectRecursively}
            redirect="list"
        >
            <DayScheduleForm />
        </Create>
    );
};

export const DayScheduleForm = () => {
    return (
        <SimpleForm sx={{ maxWidth: 800 }}>
            <TextInput source="name" required />
            <ReferenceInput source="categoryId" reference="categories">
                <SelectInput optionText="name" required />
            </ReferenceInput>
            <ArrayInput
                label="resources.days-schedules.fields.periods.label"
                source="periods"
            >
                <SimpleFormIterator sx={{mt:1}} inline>
                    <TextInput source="name" sx={{mt:2}} required />
                    <TextInput label="resources.days-schedules.fields.periods.startingTime" source="startingTime" sx={{width:100, mr:5, mt:2}} defaultValue='00:00' />
                    <TextInput label="resources.days-schedules.fields.periods.endingTime" source="endingTime" sx={{width:100, mt:2}} defaultValue='00:00' />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    );
};
