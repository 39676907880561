import React from 'react';
import {
    Show,
    TextField,
    ChipField,
    SimpleShowLayout,
    ReferenceField,
    WrapperField,
    useTranslate,
} from 'react-admin';

export const SiteShow = () => {
    const translate = useTranslate();

    return (
        <Show title={<TextField source="name" />}>
            <SimpleShowLayout>
                <TextField source="name" />
                <ReferenceField
                    source="categoryId"
                    reference="categories"
                    link="show"
                >
                    <ChipField source="name" />
                </ReferenceField>
                <WrapperField label={translate(`resources.users.fieldGroups.address`, {
                    smart_count: 2,
                })}>
                    <TextField source="location.addressLine1"/>,
                    <TextField source="location.postalCode" sx={{ml:0.5}} />
                    <TextField source="location.city" sx={{ml:0.5}}/>
                </WrapperField>
            </SimpleShowLayout>
        </Show>
    );
};
