import UserIcon from '@mui/icons-material/People';

import { ReconciliationList } from './ReconciliationList';
import { ReconciliationEdit } from './ReconciliationEdit';

export default {
    list: ReconciliationList,
    edit: ReconciliationEdit,
    icon: UserIcon,
};
