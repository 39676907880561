import React from 'react';
import { Show, TextField, useTranslate, SimpleShowLayout, DateField, BooleanField } from 'react-admin';

export const DeviceShow = () => {
    return (
        <Show title={<TextField source="name" />}>
            <SimpleShowLayout>
                <TextField source="name" />
                <TextField source="synchronizationCode" />
                <DateField
                    source="lastPingAt"
                    options={{
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    }}
                />
                <TextField source="appVersion" />
                <BooleanField source="isActive" />
            </SimpleShowLayout>
        </Show>
    );
};
