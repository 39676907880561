import {
    ChipField,
    FieldProps,
    ReferenceField,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { UserRecord } from '../../../types';
import React from 'react';
import { RoleEnum } from '../../../constants';

export const RoleField = (props: FieldProps<UserRecord>) => {
    const record = useRecordContext<UserRecord>();
    const translate = useTranslate();

    return record ? (
        <span>
            <span>
                {translate(`resources.users.fields.role.${record.role}`)}
            </span>

            {record.role === RoleEnum.CATEGORY_MANAGER && (
                <ReferenceField
                    source="managedCategoryId"
                    reference="categories"
                    link="show"
                >
                    <ChipField source="name" size="small" sortable={false} />
                </ReferenceField>
            )}
        </span>
    ) : null;
};

RoleField.defaultProps = { label: 'resources.users.fields.role.label' };
