import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    required,
    minLength,
    SimpleFormIterator,
    SelectInput,
    ArrayInput,
    DateInput,
    ReferenceInput,
    useGetList,
    FormDataConsumer,
    BooleanInput,
    CheckboxGroupInput,
} from 'react-admin';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {
    dateFormatter,
    dateStringToIsoString,
} from '../../helpers/date.helper';
import { Box } from '@mui/material';
import Separator from '../../components/Separator';
import SectionTitle from '../../components/SectionTitle';
import { addDays, parseISO, addMonths } from 'date-fns';

const validateName = [required(), minLength(2)];

export const PlanningCreate = () => {
    return (
        <Create redirect="list">
            <PlanningForm />
        </Create>
    );
};

export const PlanningForm = () => {

    const currentDate = new Date();

    return (
        <SimpleForm>
            <TextInput source="name" validate={validateName} />
            <ReferenceInput source="categoryId" reference="categories">
                <SelectInput optionText="name" isRequired />
            </ReferenceInput>
            <DateInput source="startingDate" defaultValue={currentDate} />
            <DateInput source="endingDate" defaultValue={addMonths(currentDate, 6)} />
            <Separator />

            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData.categoryId && (
                    <>
                        <Box>
                            <SectionTitle label="resources.plannings.fields.cycles.label" />
                            <ArrayInput source="cycles" label={false}>
                                <SimpleFormIterator
                                    getItemLabel={() => ''}
                                    disableReordering
                                >
                                    <DateInput
                                        source="startingDate"
                                        defaultValue={new Date()}
                                    />
                                    <DateInput
                                        source="endingDate"
                                        defaultValue={new Date()}
                                    />
                                    <ReferenceInput
                                        source="cycleId"
                                        reference="cycles"
                                        filter={{
                                            categoryId: formData.categoryId,
                                        }}
                                    >
                                        <SelectInput optionText="name" />
                                    </ReferenceInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>

                        <Separator />
                        <Box>
                            <SectionTitle label="resources.plannings.fields.specificDays.label" />
                            <ArrayInput source="specificDays" label={false}>
                                <SimpleFormIterator
                                    getItemLabel={() => ''}
                                    disableReordering
                                >
                                    <DateInput
                                        source="date"
                                        defaultValue={new Date()}
                                    />
                                    <ReferenceInput
                                        source="dayScheduleId"
                                        reference="days-schedules"
                                        filter={{
                                            categoryId: formData.categoryId,
                                        }}
                                    >
                                        <SelectInput optionText="name" />
                                    </ReferenceInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>

                        <Separator />
                        <Box>
                            <SectionTitle label="resources.plannings.fields.closingPeriods.label" />
                            <ArrayInput source="closingPeriods" label={false}>
                                <SimpleFormIterator
                                    getItemLabel={() => ''}
                                    disableReordering
                                >
                                    <TextInput source="name" validate={[required()]} />
                                    <DateInput
                                        source="startingDate"
                                        defaultValue={new Date()}
                                    />
                                    <DateInput
                                        source="endingDate"
                                        defaultValue={new Date()}
                                    />
                                    <BooleanInput source="isPaidLeave" />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>

                        <Separator />
                        <Box>
                            <SectionTitle label="resources.plannings.fields.publicHolidays.label" />
                            <PublicHolidays
                                startingDateStringIso={formData?.startingDate}
                                endingDateStringIso={formData?.endingDate}
                            />
                        </Box>
                        {/*<Separator />*/}
                        {/*<Box>*/}
                        {/*    <SectionTitle label="resources.plannings.balancePreview.title" />*/}
                        {/*    <BasicTable />*/}
                        {/*</Box>*/}
                    </>
                )}
            </FormDataConsumer>
        </SimpleForm>
    );
};
interface PublicHolidaysProps {
    startingDateStringIso: Date;
    endingDateStringIso: Date;
}

const PublicHolidays = ({
    startingDateStringIso,
    endingDateStringIso,
}: PublicHolidaysProps) => {
    if (!startingDateStringIso || !endingDateStringIso) {
        return null;
    }

    // TODO FIX ME it should compare date without the gmt +2
    const { data, isLoading, error } = useGetList('public-holidays', {
        pagination: { page: 1, perPage: 50 },
        sort: { field: 'date', order: 'ASC' },
        filter: {
            /*date_gte: addDays(
                parseISO(
                    dateStringToIsoString(dateFormatter(startingDateStringIso))
                ),
                -2
            ),*/
            date_gte: startingDateStringIso,
            date_lte: endingDateStringIso,
            /*date_lte: addDays(
                parseISO(
                    dateStringToIsoString(dateFormatter(endingDateStringIso))
                ),
                2
            ),*/
        },
    });

    if (isLoading) {
        return <p>Loading</p>;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    return (
        <CheckboxGroupInput
            source="publicHolidays"
            choices={data}
            format={v => {
                if (v) {
                    // @ts-ignore
                    const publicHolidaysId = v.map(({ id }) => id);
                    return publicHolidaysId;
                }
            }}
            parse={v => {
                if (v) {
                    const publicHolidays = v.map((value: string) => {
                        return {
                            id: value,
                        };
                    });
                    return publicHolidays;
                }
            }}
            label={false}
            row={false}
        />
    );
};

function createData(
    weekPeriod: string,
    monday: number,
    tuesday: number,
    wednesday: number,
    thirsday: number,
    friday: number,
    saturday: number,
    sunday: number,
    weekTotal: number,
    monthTotal: number
) {
    return {
        weekPeriod,
        monday,
        tuesday,
        wednesday,
        thirsday,
        friday,
        saturday,
        sunday,
        weekTotal,
        monthTotal,
    };
}

const rows = [createData('03/01 - 09/01', 90, 90, 90, 90, 90, 90, 90, 90, 90)];

export default function BasicTable() {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ width: 1 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Janvier</TableCell>
                        <TableCell align="right">Lundi</TableCell>
                        <TableCell align="right">Mardi</TableCell>
                        <TableCell align="right">Mercredi</TableCell>
                        <TableCell align="right">Jeudi</TableCell>
                        <TableCell align="right">Vendredi</TableCell>
                        <TableCell align="right">Samedi</TableCell>
                        <TableCell align="right">Dimanche</TableCell>
                        <TableCell align="right">Tot.Hebdo.</TableCell>
                        <TableCell align="right">Cumul</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <TableRow
                            key={row.weekPeriod}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row.weekPeriod}
                            </TableCell>
                            <TableCell align="center">{row.monday}</TableCell>
                            <TableCell align="center">{row.tuesday}</TableCell>
                            <TableCell align="center">
                                {row.wednesday}
                            </TableCell>
                            <TableCell align="center">{row.thirsday}</TableCell>
                            <TableCell align="center">{row.friday}</TableCell>
                            <TableCell align="center">{row.saturday}</TableCell>
                            <TableCell align="center">{row.sunday}</TableCell>
                            <TableCell align="center">
                                {row.weekTotal}
                            </TableCell>
                            <TableCell align="center">
                                {row.monthTotal}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
