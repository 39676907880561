import React, {useState} from "react";
import {Form, minLength, required, TextInput, useDataProvider, useGetOne, useRedirect, useTranslate} from "react-admin";
import Box from "@mui/material/Box";
import {Avatar, Button, Card, CardActions, CircularProgress} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import toast from "react-hot-toast";

const SetPassword: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const redirect = useRedirect()

    const handleSubmit = (data: FormValues) => {
        if (data.password !== data.confirmPassword) {
            toast.error('Les mots de passe ne correspondent pas')
            return
        }

        setLoading(true);

        // @ts-ignore
        fetch(`${process.env.REACT_APP_API_URL}/users/update/mdp/${JSON.parse(localStorage.user).id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({mdp: data.password})
        }).then((response) => {
            if (response.status === 200) {
                toast.success('Mot de passe mis à jour')
                setLoading(false)
                redirect("/")
            } else {
                toast.error('Une erreur est survenue')
                setLoading(false)
            }
        })
    };

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                <Card sx={{minWidth: 300, marginTop: '6em'}}>
                    <Box
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Avatar sx={{bgcolor: 'secondary.main'}}>
                            <LockIcon/>
                        </Avatar>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            color: theme => theme.palette.grey[500],
                        }}
                    ></Box>
                    <Box sx={{padding: '0 1em 1em 1em'}}>
                        <Box sx={{marginTop: '1em'}}>
                            <TextInput
                                autoFocus
                                source="password"
                                label={translate('updatePassword.password')}
                                type="password"
                                disabled={loading}
                                validate={[required(), minLength(6)]}
                                fullWidth
                            />
                        </Box>
                        <Box sx={{marginTop: '1em'}}>
                            <TextInput
                                source="confirmPassword"
                                label={translate('updatePassword.confirmPassword')}
                                type="password"
                                disabled={loading}
                                validate={[required(), minLength(6)]}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <CardActions sx={{padding: '0 1em 1em 1em'}}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            fullWidth
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2}/>
                            )}
                            {translate('updatePassword.submit')}
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    );
}

export default SetPassword

interface FormValues {
    password?: string;
    confirmPassword?: string;
}
