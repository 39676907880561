import React from 'react';
import { Edit, TextField } from 'react-admin';
import { SiteForm, siteTransform } from './SiteCreate';

export const SiteEdit = () => {
    return (
        <Edit title={<TextField source="name" />} transform={siteTransform} redirect="show">
            <SiteForm />
        </Edit>
    );
};
