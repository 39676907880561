import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
    TextInput,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextField,
    BooleanInput,
} from 'react-admin';
import { ReplaceEmptyStringsByNullInObjectRecursively } from '../../utils/form';

export const DeviceCreate = () => {
    return (
        <Create
            title={<TextField source="name" />}
            transform={ReplaceEmptyStringsByNullInObjectRecursively}
            redirect="show"
        >
            <DeviceForm />
        </Create>
    );
};

export const deviceValidationSchema = yup
    .object()
    .shape({
        name: yup.string().required(),
        siteId: yup.string().required(),
    })
    .required();

export const DeviceForm = () => {
    return (
        <SimpleForm
            resolver={yupResolver(deviceValidationSchema)}
            sx={{ maxWidth: 500 }}
        >
            <TextInput source="name" fullWidth isRequired />
            <ReferenceInput source="siteId" reference="sites">
                <SelectInput optionText="name" isRequired />
            </ReferenceInput>
            <BooleanInput
                    source="isActive"
                    label="resources.devices.fields.isActive"
                    defaultValue={true}
                />
        </SimpleForm>
    );
};
