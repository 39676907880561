import React from 'react';
import { Edit, TextField, ShowButton, ListButton, TopToolbar } from 'react-admin';
import { PlanningForm } from './PlanningCreate';
import Button from '@mui/material/Button';

const PostEditActions = () => (
    <TopToolbar>
        <ShowButton />
        {/* Add your custom actions */}
    </TopToolbar>
);

export const PlanningEdit = () => {
    return (
        <Edit
            title={<TextField source="name" />}
            actions={<PostEditActions />}
            redirect="list"
        >
            <PlanningForm />
        </Edit>
    );
};
