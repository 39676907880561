import { Divider } from '@mui/material';
import * as React from 'react';

const Separator = () => (
    <Divider
        sx={{
            width: '100%',
            borderTop: 'solid black 1px',
        }}
    />
);

export default Separator;
