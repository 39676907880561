import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import { DeviceCreate } from './DeviceCreate';
import { DeviceEdit } from './DeviceEdit';
import { DeviceList } from './DeviceList';
import { DeviceShow } from './DeviceShow';

export default {
    create: DeviceCreate,
    edit: DeviceEdit,
    list: DeviceList,
    show: DeviceShow,
    icon: TabletAndroidIcon,
};
