import React, { ReactElement } from 'react';

import {
    Datagrid,
    TextField,
    BooleanField,
    EditButton,
    ShowButton,
    TopToolbar,
    CreateButton,
    sanitizeListRestProps,
    ListActionsProps,
    List,
    ReferenceField,
    ChipField,
} from 'react-admin';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

const ListActions = ({
    className,
    ...rest
}: ListActionsProps): ReactElement => {
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <CreateButton />
        </TopToolbar>
    );
};


export const DeviceList = () => {
    return (
        <List
            empty={false}
            actions={<ListActions />}
            sort={{ field: 'name', order: 'ASC' }}
        >
            <Datagrid bulkActionButtons={false} sx={{ ".RaDatagrid-headerCell": {
                backgroundColor: "#6E77DA",
                fontSize: 16,
                color: "white"
            }}}>
                <TextField source="name" />
                <ReferenceField source="siteId" reference="sites" link={false}>
                    <ChipField source="name" icon={<HomeWorkIcon />} color="info" />
                </ReferenceField>
                <BooleanField source="isSynchronized" />
                <BooleanField source="isActive" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    );
};
