import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { SiteCreate } from './SiteCreate';
import { SiteList } from './SiteList';
import { SiteShow } from './SiteShow';
import { SiteEdit } from './SiteEdit';

export default {
    create: SiteCreate,
    edit: SiteEdit,
    list: SiteList,
    show: SiteShow,
    icon: MapsHomeWorkIcon,
};
