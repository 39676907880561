import React, { ReactElement } from 'react';
import {
    Datagrid,
    ShowButton,
    TopToolbar,
    CreateButton,
    EditButton,
    sanitizeListRestProps,
    ListActionsProps,
    TextField,
    List,
    ChipField,
    ReferenceField,
} from 'react-admin';
import { DurationField } from '../../common/fields/durationField';
import WorkIcon from '@mui/icons-material/Work';

const ListActions = ({
    className,
    ...rest
}: ListActionsProps): ReactElement => {
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <CreateButton />
        </TopToolbar>
    );
};


export const DayScheduleList = () => {
    return (
        <List
            actions={<ListActions />}
            empty={false}
            sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid sx={{ ".RaDatagrid-headerCell": {
                backgroundColor: "#6E77DA",
                fontSize: 16,
                color: "white"
            },
            "& .select-all": {
                display: "none"
              }}}>
                <TextField source="name" />
                <ReferenceField
                    source="categoryId"
                    reference="categories"
                    link="show"
                    label="resources.days-schedules.fields.categoryId"
                >
                    <ChipField source="name" icon={<WorkIcon />} color="info" />
                </ReferenceField>
                <DurationField source="totalMinutesOfWork" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    );
};
