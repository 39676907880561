import React from 'react';
import { Edit, TextField } from 'react-admin';
import { DayScheduleForm } from './DayScheduleCreate';
import { ReplaceEmptyStringsByNullInObjectRecursively } from '../../utils/form';

export const DayScheduleEdit = () => {
    return (
        <Edit
            title={<TextField source="name" />}
            transform={ReplaceEmptyStringsByNullInObjectRecursively}
            redirect="list"
        >
            <DayScheduleForm />
        </Edit>
    );
};
