import AvTimerIcon from '@mui/icons-material/AvTimer';

import { DayScheduleList } from './DayScheduleList';
import { DayScheduleShow } from './DayScheduleShow';
import { DayScheduleCreate } from './DayScheduleCreate';
import { DayScheduleEdit } from './DayScheduleEdit';

export default {
    list: DayScheduleList,
    create: DayScheduleCreate,
    edit: DayScheduleEdit,
    icon: AvTimerIcon,
    show: DayScheduleShow,
};
