import React from 'react';
import { Edit, ReferenceField, TextField } from 'react-admin';
import { ReplaceEmptyStringsByNullInObjectRecursively } from '../../utils/form';
import { ContractForm } from './ContractCreate';

export const ContractEdit = () => {
    return (
        <Edit
            redirect="list"
            title={<ReferenceField
                        source="userId"
                        reference="users"
                        link={false}
                    ><TextField source="fullname" />
                    </ReferenceField>}
            transform={ReplaceEmptyStringsByNullInObjectRecursively}
        >
            <ContractForm />
        </Edit>
    );
};
