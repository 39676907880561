import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { PlanningList } from './PlanningList';
import { PlanningShow } from './PlanningShow';
import { PlanningCreate } from './PlanningCreate';
import { PlanningEdit } from './PlanningEdit';

export default {
    create: PlanningCreate,
    edit: PlanningEdit,
    list: PlanningList,
    show: PlanningShow,
    icon: CalendarMonthIcon,
};
