import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState, usePermissions,
} from 'react-admin';

import absences from '../../app/absences';
import absenceTypes from '../../app/absence-types';
import users from '../../app/users';
import clockings from '../../app/clockings';
import contracts from '../../app/contracts';
import categories from '../../app/categories';
import cycles from '../../app/cycles';
import daysSchedules from '../../app/days-schedules';
import devices from '../../app/devices';
import plannings from '../../app/plannings';
import sites from '../../app/sites';

import SubMenu from '../SubMenu';
import {RoleEnum} from "../../constants";

type MenuName = 'menuPlannings' | 'menuUsers' | 'menuAbsences' | 'menuSettings';

const Index = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuPlannings: true,
        menuUsers: true,
        menuAbsences: true,
        menuSettings: true,
    });
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                //backgroundColor: 'red',
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {/*<DashboardMenuItem />*/}
            <SubMenu
                handleToggle={() => handleToggle('menuUsers')}
                isOpen={state.menuUsers}
                name="pos.subMenu.users"
                icon={<PeopleIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/users"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`pos.menu.users`, {
                        smart_count: 2,
                    })}
                    leftIcon={<users.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/contracts"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`pos.menu.contracts`, {
                        smart_count: 2,
                    })}
                    leftIcon={<contracts.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/absences"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`pos.menu.absences`, {
                        smart_count: 2,
                    })}
                    leftIcon={<absences.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/clockings"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`pos.menu.clockings`, {
                        smart_count: 2,
                    })}
                    leftIcon={<clockings.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/reconciliations"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`pos.menu.reconciliations`, {
                        smart_count: 2,
                    })}
                    leftIcon={<clockings.icon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuPlannings')}
                isOpen={state.menuPlannings}
                name="pos.subMenu.planification"
                icon={<CalendarMonthIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/days-schedules"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`pos.menu.daysSchedules`, {
                        smart_count: 2,
                    })}
                    leftIcon={<daysSchedules.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/cycles"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`pos.menu.cycles`, {
                        smart_count: 2,
                    })}
                    leftIcon={<cycles.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/plannings"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`pos.menu.plannings`, {
                        smart_count: 2,
                    })}
                    leftIcon={<plannings.icon />}
                    dense={dense}
                />
            </SubMenu>
            {permissions === RoleEnum.SUPER_ADMIN &&
                <SubMenu
                    handleToggle={() => handleToggle('menuSettings')}
                    isOpen={state.menuSettings}
                    name="pos.subMenu.settings"
                    icon={<SettingsIcon />}
                    dense={dense}
                >
                    <MenuItemLink
                        to="/absence-types"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`pos.menu.absenceTypes`, {
                            smart_count: 2,
                        })}
                        leftIcon={<absenceTypes.icon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/sites"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`pos.menu.sites`, {
                            smart_count: 2,
                        })}
                        leftIcon={<sites.icon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/categories"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`pos.menu.categories`, {
                            smart_count: 2,
                        })}
                        leftIcon={<categories.icon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/devices"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`pos.menu.devices`, {
                            smart_count: 2,
                        })}
                        leftIcon={<devices.icon />}
                        dense={dense}
                    />
                </SubMenu>
            }
        </Box>
    );
};

export default Index;
