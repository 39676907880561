import {useTranslate} from "react-admin";
import {Typography} from "@mui/material";
import React from "react";

const ArrayTitle = ({ CustomLabel }: { CustomLabel: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(CustomLabel as string)}
        </Typography>
    );
};

export default ArrayTitle;
